// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate, useBeforeUnload } from "react-router-dom";
// Layouts
import { DefaultLayout } from "@layouts/Default";
// UI Components
import { ConditionForm, EventForm, TaskForm, TaskFormCallback } from "@components/form";
import { NodeTypes, RoutineBuilder } from "@components/routine-builder";
import { FullLoadingPage } from "@components/loading/full-page";
import { Header } from "@components/header";
import { UnsavedDialog } from "@components/dialog";
// Store
import {
  SequenceElementType,
  WorkflowDefinitionStatus,
  ServiceTaskDto,
} from "@store/modules/workflow-definition";
import { Store } from "@store/index";
import { TaskDataSourceConfigurationDto } from "@store/modules/data-source/types";
// Router
import { View } from "@routes/index";
// Type guards
import { isMessageEvent } from "@utils/type-guards/message-event";
import { isServiceTask } from "@utils/type-guards/service-task";
// Translations
import { lang } from "@lang/index";

const Element = (): React.ReactElement => {
  const params = useParams();
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDataSaved, setDataSaved] = useState(0);
  const [taskDataSourceDto, setTaskDataSourceDto] = useState<TaskDataSourceConfigurationDto>();
  // Store
  const workflowDefinition = Store.useSelector((state) => state.workflowDefinition);
  const messageEvents = Store.useSelector((state) => state.messageEvents);
  const serviceTasks = Store.useSelector((state) => state.serviceTasks);
  const dataSource = Store.useSelector((state) => state.dataSource);

  const serviceTasksDataProcess = useMemo(
    () =>
      workflowDefinition?.data?.process
        ?.map((data) => {
          if (isServiceTask(data)) return data;
        })
        .filter(Boolean),
    [workflowDefinition?.data?.process]
  );

  // Not required a filter, since for now the message event/trigger is singular and will always be in the first position of the array.
  const messageEventDataProcess =
    workflowDefinition?.data?.process?.[0] && isMessageEvent(workflowDefinition?.data?.process?.[0])
      ? workflowDefinition?.data?.process?.[0]
      : undefined;

  // Check if exists any API request pending.
  const isLoading =
    !!workflowDefinition.loading ||
    !!serviceTasks.loading ||
    !!messageEvents.loading ||
    !!dataSource.loading;

  // Check if we can publish the workflow definition, must have at least one message event/trigger and one service task/action.
  const canPublish = useMemo(
    () => messageEventDataProcess && !!serviceTasksDataProcess?.length,
    [messageEventDataProcess, serviceTasksDataProcess]
  );

  const dispatch = Store.useDispatch();

  useEffect(() => {
    if (messageEvents.data || messageEvents.loading || messageEvents.error) return;

    // Get Message Events / Trigger
    void dispatch(Store.messageEvents.fetch());
  }, [dispatch, messageEvents]);

  useEffect(() => {
    if (serviceTasks.data || serviceTasks.loading || serviceTasks.error) return;

    // Get Service Tasks / Action
    void dispatch(Store.serviceTasks.fetch());
  }, [dispatch, serviceTasks]);

  useEffect(() => {
    if (dataSource.data || dataSource.loading || dataSource.error || !taskDataSourceDto) return;
    void dispatch(
      Store.dataSource.fetch({
        dto: taskDataSourceDto,
      })
    );
  }, [dispatch, dataSource, taskDataSourceDto]);

  useEffect(() => {
    if (!params.id) return;

    // Get Workflow Definition
    void dispatch(
      Store.workflowDefinition.fetch({
        workflowDefinitionId: params.id,
      })
    );
  }, [dispatch, params]);

  /**
   * Function responsible to submit the selected trigger/message event, to inside workflow definition.
   *
   * @param value - event type
   */
  const onHandleSubmitEvent = useCallback(
    (value: string) => {
      if (!workflowDefinition.data) return;

      const {
        _id: workflowDefinitionId,
        process: processData,
        startEventSequenceId,
      } = workflowDefinition.data;

      if (!startEventSequenceId)
        throw new Error("Not possible create trigger without `startEventSequenceId`!");

      // check if exits any process inside workflow definition. (will not be possible to create actions without trigger)
      // if process exist, let's update the event, otherwise create a new event.
      const data =
        processData && processData.length > 0
          ? processData.map((item) => {
              if (item.sequenceId === startEventSequenceId) {
                return {
                  ...item,
                  name: value,
                  eventType: [value],
                };
              }
              return item;
            })
          : [
              {
                __t: SequenceElementType.MessageStartEvent,
                name: value,
                sequenceId: startEventSequenceId,
                eventType: [value],
                targetRef: null,
              },
            ];

      void dispatch(
        Store.workflowDefinition.update({
          workflowDefinitionId,
          data: {
            process: data,
          },
        })
      );
    },
    [workflowDefinition.data, dispatch]
  );

  /**
   * Function responsible to submit the selected action/service tasks, to inside workflow definition.
   *
   * @param id - service task ID
   * @param taskType - task type
   * @param headers - data from form fields
   */
  const onHandleSubmitTasks = useCallback(
    ({ id, taskType, headers }: TaskFormCallback, index?: number) => {
      if (!workflowDefinition.data) return;
      const { _id: workflowDefinitionId, process: processData } = workflowDefinition.data;

      if (!processData) throw new Error("Not possible create Action without trigger!");

      const data = processData.map((item) => {
        // update message event with `targetRef` of new element.
        if (item.__t === SequenceElementType.MessageStartEvent) {
          return {
            ...item,
            targetRef: "targetRef" in item ? item.targetRef : id,
          };
        }

        // if service tasks already exists, update with new submitted data.
        if (item.__t === SequenceElementType.ServiceTask && item.sequenceId === id) {
          return {
            ...item,
            name: taskType,
            taskType,
            headers: headers || null,
          };
        }

        return item;
      });

      if (!data) throw new Error("Empty process, cannot update actions.");

      // check if exists any service tasks with received ID, if not, we should create a new one.
      const serviceTaskExists = data.find((data) => data.sequenceId === id);
      if (!serviceTaskExists) {
        if (!index) return;

        const previousProcess = { ...data[index - 1], targetRef: id };
        const nextProcess = index < data.length ? { ...data[index], sourceRef: id } : undefined;

        const newServiceTask: ServiceTaskDto = {
          name: taskType,
          sequenceId: id,
          taskType,
          sourceRef: previousProcess.sequenceId,
          targetRef: nextProcess?.sequenceId || null,
          headers: headers || null,
          __t: SequenceElementType.ServiceTask,
        };
        nextProcess
          ? data.splice(index - 1, 2, previousProcess, newServiceTask, nextProcess)
          : data.splice(index - 1, 1, previousProcess, newServiceTask);
      }

      void dispatch(
        Store.workflowDefinition.update({
          workflowDefinitionId,
          data: {
            process: data,
          },
        })
      );
    },
    [dispatch, workflowDefinition]
  );

  /**
   * Function responsible to delete action/service tasks, from workflow definition.
   *
   * @param id - service tasks ID
   */
  const onHandleDeleteTask = useCallback(
    (id: string) => {
      if (!workflowDefinition.data) return;
      const { _id: workflowDefinitionId, process: processData } = workflowDefinition.data;

      if (!processData) throw new Error("Not possible delete an Action.");

      // current index of service tasks that we want delete.
      const idx = processData.findIndex((value) => value.sequenceId === id);
      const process = processData
        // remove element from list of service tasks.
        .filter((item) => item.sequenceId !== id)
        .map((item, index, array) => {
          // update the `targetRef` of previous element with `sequenceId` of next element.
          if (index === idx - 1 && array[idx]) {
            return {
              ...item,
              targetRef: array[idx].sequenceId,
            };
            // update `sourceRef` of current element with `sequenceId` of previous element.
          } else if (index === idx && array[idx - 1]) {
            return {
              ...item,
              sourceRef: array[idx - 1].sequenceId,
            };
          }
          // update `targetRef` of current element if it's the last.
          if (!array[index + 1]) {
            return {
              ...item,
              targetRef: null,
            };
          }

          return item;
        });

      void dispatch(
        Store.workflowDefinition.update({
          workflowDefinitionId,
          data: {
            process,
          },
        })
      );
    },
    [dispatch, workflowDefinition]
  );

  const onHandlePublish = useCallback(() => {
    if (!params.id) return;
    void dispatch(
      Store.workflowDefinition.status({
        workflowDefinitionId: params.id,
        newStatus: WorkflowDefinitionStatus.Active,
        onSuccess: () => {
          if (!params.id) return;
          void dispatch(
            Store.workflowDefinition.fetch({
              workflowDefinitionId: params.id,
            })
          );
        },
      })
    );
  }, [dispatch, params.id]);

  const onHandleArchive = useCallback(() => {
    if (!params.id) return;

    void dispatch(
      Store.workflowDefinition.archive({
        workflowDefinitionId: params.id,
        isArchived: workflowDefinition.data?.archived,
        onSuccess: () => {
          if (!params.id) return;
          void dispatch(
            Store.workflowDefinition.fetch({
              workflowDefinitionId: params.id,
            })
          );
        },
      })
    );
  }, [dispatch, params.id, workflowDefinition.data?.archived]);

  // Function will be called any time advisor change the form, that way will be possible for us to detect if
  // exists any unsaved changes, to properly give the feedback when they click on the "Back" button.
  const onHandleFormChange = useCallback(({ isFormDataSaved }: { isFormDataSaved: boolean }) => {
    setDataSaved((prev) => {
      // Hack to keep 0 until first editing, if everything are saved.
      if (prev === 0 && isFormDataSaved) return 0;
      if (!isFormDataSaved) return prev - 1;
      return prev + 1;
    });
  }, []);

  const onHandleTaskTypeChange = useCallback((taskType: string) => {
    if (taskType) {
      setTaskDataSourceDto({
        taskType: taskType || "",
        dataSourceType: "TemplateTypes", // TODO: need to be discussed with BE team, but for MVP it's only required the `TemplateTypes`.
      });
    }
  }, []);

  // Before Unload, check if form was changed and not saved before reloading the page.
  useBeforeUnload(
    useCallback(
      (event) => {
        if (!isDataSaved) return;
        event.preventDefault();
        event.returnValue = lang("DIALOG_UNSAVED_CHANGES");
      },
      [isDataSaved]
    )
  );

  const nodeTypes: NodeTypes[] = useMemo(
    () => [
      {
        type: "task",
        icon: "essentional-mouse-circle",
        skin: "oasis",
        renderNode: (index, removeIncremental): React.ReactElement => (
          <TaskForm
            isWorkflowDefinitionActive={
              workflowDefinition.data?.status === WorkflowDefinitionStatus.Active
            }
            serviceTasks={serviceTasks.data}
            dataSourceOptions={dataSource.data}
            onSave={(options): void => {
              onHandleSubmitTasks(options, index);
              removeIncremental?.();
            }}
            onChange={onHandleFormChange}
            onChangeTaskType={onHandleTaskTypeChange}
            onDelete={removeIncremental}
          />
        ),
      },
      {
        type: "condition",
        // can't find cpu-charge icon in DS
        icon: "essentional-computing",
        renderNode: () => (
          <ConditionForm
            isWorkflowDefinitionActive={
              workflowDefinition.data?.status === WorkflowDefinitionStatus.Active
            }
            // TODO: add save function
            onSave={(): void => undefined}
            onChange={onHandleFormChange}
          />
        ),
      },
    ],
    [
      onHandleFormChange,
      onHandleTaskTypeChange,
      onHandleSubmitTasks,
      serviceTasks.data,
      dataSource.data,
      workflowDefinition.data?.status,
    ]
  );

  return (
    <DefaultLayout>
      {isLoading && <FullLoadingPage />}
      <Header
        title={workflowDefinition.data?.name}
        isArchived={workflowDefinition.data?.archived}
        isPublished={workflowDefinition.data?.status === WorkflowDefinitionStatus.Active}
        onBack={(): void => {
          !isDataSaved ? navigate(-1) : setDialogOpen(true);
        }}
        onArchive={workflowDefinition.data ? onHandleArchive : undefined}
        onPublish={canPublish ? onHandlePublish : undefined}
      />

      {workflowDefinition.data && (
        <RoutineBuilder
          nodeTypes={nodeTypes}
          // disallow incremental if wf is published, if there are unsaved changes or if there is no message event.
          incrementDisabled={
            workflowDefinition.data.status === WorkflowDefinitionStatus.Active ||
            isDataSaved < 0 ||
            !messageEventDataProcess
          }
        >
          <EventForm
            isWorkflowDefinitionActive={
              workflowDefinition.data?.status === WorkflowDefinitionStatus.Active
            }
            messageEvents={messageEvents.data}
            selectedEventType={messageEventDataProcess?.eventType?.toString() || ""}
            onSave={onHandleSubmitEvent}
            onChange={onHandleFormChange}
          />

          {serviceTasksDataProcess?.map((data) => (
            <TaskForm
              key={`task-form-${data?.sequenceId}`}
              id={data?.sequenceId}
              isWorkflowDefinitionActive={
                workflowDefinition.data?.status === WorkflowDefinitionStatus.Active
              }
              serviceTasks={serviceTasks.data}
              selectedTaskType={data?.taskType || ""}
              headers={data?.headers || undefined}
              dataSourceOptions={dataSource.data}
              onSave={onHandleSubmitTasks}
              onChange={onHandleFormChange}
              onChangeTaskType={onHandleTaskTypeChange}
              onDelete={onHandleDeleteTask}
            />
          ))}
        </RoutineBuilder>
      )}

      <UnsavedDialog
        isOpen={isDialogOpen}
        onClickPrimary={(): void => {
          setDialogOpen(false);
        }}
        onClickSecondary={(): void => {
          setDialogOpen(false);
          navigate(-1);
        }}
      >
        {lang("DIALOG_UNSAVED_CHANGES")}
      </UnsavedDialog>
    </DefaultLayout>
  );
};

export const workflowDefinitionDetails: View = {
  name: lang("WORKFLOW_DEFINITION_DETAILS"),
  options: {
    path: "/workflow-definition/details/:id",
    element: <Element />,
  },
};
