// Styles
import "./styles/index.scss";
// React
import React, { useState } from "react";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { ActionButton } from "@components/button";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfIcon } from "@advicefront/ds-icon";
// Class names
import { useClassNames } from "@advicefront/ds-base";
// Types
import { IncrementalType, NodeTypes } from "./RoutineBuilder";

interface RoutineElementProps {
  /** Possible nodes that can be used on Routine Builder */
  nodeTypes?: NodeTypes[];
  /** enable/disable incrementing */
  incrementDisabled?: boolean;
  /** Incremental to be added */
  incrementalType?: IncrementalType;
  /** Cb to set which incremental should be added */
  onClick: (type: string) => void;
  /** routine element */
  children: React.ReactNode;
}
/**
 * This component displays the routine element with incrementor component.
 *
 * @example
 * ```tsx
 * <RoutineElement onClick=((type)=>console.log(type))>{child}</RoutineElement>
 * ```
 *
 * @param props - RoutineElementProps
 */

export const RoutineElement = ({
  nodeTypes,
  incrementDisabled,
  incrementalType,
  onClick,
  children,
}: RoutineElementProps): React.ReactElement => {
  const rootClassName = "routine-builder";
  const cssClasses = {
    element: useClassNames(`${rootClassName}__element`),
  };

  const [isIncrementorOpen, setIsIncrementorOpen] = useState<boolean>(false);

  return (
    <>
      <AfRow orientation="vertical" spacing="none">
        <AfCol offsetBefore={1} offsetAfter={1} className={cssClasses.element}>
          {children}
        </AfCol>
      </AfRow>
      <AfRow justify="center" spacing="none">
        <AfCol className={cssClasses.element}>
          {isIncrementorOpen && nodeTypes ? (
            <AfCard padding="s">
              <AfRow spacing="sm" wrap>
                {nodeTypes?.map((option) => (
                  <AfCol key={option.type}>
                    <ActionButton
                      icon={option.icon}
                      skin={option.skin}
                      onClick={(): void => {
                        onClick(option.type);
                        setIsIncrementorOpen(false);
                      }}
                    >
                      {option.type}
                    </ActionButton>
                  </AfCol>
                ))}
              </AfRow>
            </AfCard>
          ) : (
            <AfButton
              disabled={!!incrementDisabled || !!incrementalType}
              icon={<AfIcon name="essentional-add-square" />}
              onClick={(): void => setIsIncrementorOpen(true)}
            />
          )}
        </AfCol>
      </AfRow>
    </>
  );
};
