// React
import React, { useRef, useState, useCallback } from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfDropdown } from "@advicefront/ds-dropdown";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
// Translations
import { lang } from "@lang/index";

interface RoutineCardProps {
  /** The content for the title of the card. */
  title: string;
  /** Icon to display to the left of the title content. */
  icon?: React.ReactNode;
  /** Check is dropdown is disabled */
  isDropdownDisabled?: boolean;
  /** Hide submit button, disallowing save interaction. */
  isSubmitHidden?: boolean;
  /** Callback fired when triggered clean action. */
  onClean?: () => void;
  /** Callback fired when triggered delete action. */
  onDelete?: () => void;
  /** The content to display inside card. */
  children: React.ReactNode;
}

/**
 * This component displays routine card.
 *
 * @example
 * ```tsx
 * <RoutineCard title="Message Event">
 *   Children
 * </RoutineCard>
 * ```
 *
 * @param props - RoutineCardProps
 */
export const RoutineCard = ({
  title,
  icon,
  isSubmitHidden,
  isDropdownDisabled,
  onClean,
  onDelete,
  children,
}: RoutineCardProps): React.ReactElement => {
  const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const onHandleClean = useCallback(() => {
    setDropdownOpen(false);
    if (onClean) onClean();
  }, [onClean]);

  const onHandleDelete = useCallback(() => {
    setDropdownOpen(false);
    if (onDelete) onDelete();
  }, [onDelete]);

  return (
    <AfCard border={false}>
      <AfCard.Header>
        <AfRow align="center">
          {icon && <AfCol>{icon}</AfCol>}
          <AfCol size="auto">
            <AfTypography type="h4" hasMargin={false}>
              {title}
            </AfTypography>
          </AfCol>
          {(onClean || onDelete) && (
            <AfCol>
              <AfButton
                type="button"
                disabled={!!isDropdownDisabled}
                ref={dropdownBtnRef}
                size="s"
                icon={<AfIcon name="basic-more" />}
                skin="ghost"
                onClick={(): void => setDropdownOpen(!isDropdownOpen)}
              />
              <AfDropdown
                isOpen={isDropdownOpen}
                anchorRef={dropdownBtnRef}
                onClose={(): void => setDropdownOpen(false)}
              >
                <AfActionList>
                  {onClean && (
                    <AfActionList.Item onClick={onHandleClean}>
                      {lang("FORMS_CLEAN", title)}
                    </AfActionList.Item>
                  )}
                  {onDelete && (
                    <AfActionList.Item
                      leftNode={<AfIcon name="basic-trash" size="xs" />}
                      onClick={onHandleDelete}
                    >
                      {lang("FORMS_DELETE")}
                    </AfActionList.Item>
                  )}
                </AfActionList>
              </AfDropdown>
            </AfCol>
          )}
        </AfRow>
      </AfCard.Header>
      {children}
      {!isSubmitHidden && (
        <AfCard.Footer>
          <AfRow>
            <AfCol size={12}>
              <AfButton type="submit" fullWidth>
                {lang("FORMS_SAVE")}
              </AfButton>
            </AfCol>
          </AfRow>
        </AfCard.Footer>
      )}
    </AfCard>
  );
};
