/**
 * Function takes in a `date` string and returns an object with two properties - `date` and `hour`.
 *
 * @param date - The date.
 * @returns An object with the `date` property set to the formatted and the `hour` property set to the formatted time
 * with the "h" string appended to indicate it's in hours.
 */
export function formatDate(date: string): { date: string; hour: string } {
  const dt = new Date(date);

  const intlDate = new Intl.DateTimeFormat("en-US").format(dt);
  const intlHour = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(dt);

  return { date: intlDate, hour: intlHour };
}
