// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.workflowExecutions.reset();
 * ```
 */

export const reset = createAction("workflow-executions/reset");

/**
 * Fetch all workflow executions according their Workflow Definition ID.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowExecutions.fetch({
 *     workflowDefinitionIds
 *   })
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  {
    authToken: string;
    workflowDefinitionIds: string[];
  }
>("workflow-executions/fetch", async ({ authToken, workflowDefinitionIds }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const response = await Promise.all(
      workflowDefinitionIds.map(async (workflowDefinitionId) => {
        const r = await API.WorkflowAPI(authToken)?.findAllWorkflowExecutions({
          orderBy: "updatedAt",
          orderDirection: "desc",
          workflowDefinitionId,
        });

        return { ...r.data, id: workflowDefinitionId };
      })
    );

    data = response;
  } catch (e) {
    error = API.parseError(e);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
