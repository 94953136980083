import { StateProps } from "./types";

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

export const initialState: StateProps = {
  loading: 0,
  error: null,
  data: undefined,
};
