// Styles
import "./styles/index.scss";
// React
import React from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfCard } from "@advicefront/ds-card";
import { AfSkeleton } from "@advicefront/ds-skeleton";
import { useClassNames } from "@advicefront/ds-base";

/**
 * This component displays Skeleton Action Card .
 *
 * @example
 * ```tsx
 * <SkeletonActionCard />
 * ```
 */
export const SkeletonActionCard = (): React.ReactElement => {
  const rootClassName = "skeleton-action-card";
  const cssClasses = {
    row: useClassNames(`${rootClassName}__row`),
  };

  return (
    <AfCard border={false}>
      <AfCard.Header>
        <AfSkeleton shape="line" size="xl" />
      </AfCard.Header>
      <AfRow className={cssClasses.row} spacing="none" wrap>
        <AfCol size={9}>
          <AfSkeleton shape="line" size="m" />
        </AfCol>
        <AfCol size={7}>
          <AfSkeleton shape="line" size="m" />
        </AfCol>
        <AfCol size={5}>
          <AfSkeleton shape="line" size="m" />
        </AfCol>
      </AfRow>
      <AfCard.Footer>
        <AfSkeleton shape="line" size="l" radius="l" />
      </AfCard.Footer>
    </AfCard>
  );
};
