/**
 * Checks if a value is of type `string`.
 *
 * @param val - The value to check.
 * @returns `true` if the value is a string, `false` otherwise.
 */
export function isString(val: unknown): val is string {
  return typeof val === "string";
}

/**
 * Checks if a value is of type `boolean`.
 *
 * @param val - The value to check.
 * @returns `true` if the value is a boolean, `false` otherwise.
 */
export function isBoolean(val: unknown): val is boolean {
  return typeof val === "boolean";
}
