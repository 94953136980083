// React
import React from "react";
// Router
import { RouteProps } from "react-router-dom";
// Views
import * as Views from "@views/index";
import { ViewRedirect } from "./utils/ViewRedirect";

// DOCS: https://beta.reactrouter.com/en/v6.3.0/upgrading/v5#upgrade-all-switch-elements-to-routes

export interface View {
  // Route name / title
  name: string;
  // https://reactrouter.com/web/example/basic
  options: RouteProps;
}

const redirect: View = {
  name: "Index",
  options: {
    path: "/",
    element: <ViewRedirect route={Views.workflowDefinitions} />,
  },
};

export const allRoutes = {
  redirect,
  ...Views,
};

export type RouteIndex = keyof typeof allRoutes;

// Generate routes list from grouped imports object
export const routes: View[] = Object.values(allRoutes);
