// React
import React from "react";
// UI Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";
// Translations
import { lang } from "@lang/index";

interface UnsavedDialogProps {
  /** Controls whether the dialog is open or not. */
  isOpen: boolean;
  /** Callback fired when clicked on primary action. */
  onClickPrimary: () => void;
  /** Callback fired when clicked on secondary action. */
  onClickSecondary?: () => void;
  /** The content to display inside dialog. */
  children?: React.ReactNode;
}

/**
 * This component displays dialog for unsaved changes.
 *
 * @example
 * ```tsx
 * <UnsavedDialog isOpen={false} onClickPrimary={() => {}} />
 * ```
 *
 * @param props - UnsavedDialogProps
 */
export const UnsavedDialog = ({
  isOpen,
  onClickPrimary,
  onClickSecondary,
  children,
}: UnsavedDialogProps): React.ReactElement => (
  <AfDialog
    isOpen={isOpen}
    title={lang("DIALOG_SAVE_BEFORE")}
    skin="warning"
    primaryAction={<AfButton onClick={onClickPrimary}>{lang("DIALOG_CONTINUE_EDITING")}</AfButton>}
    secondaryAction={
      onClickSecondary ? (
        <AfButton skin="secondary" onClick={onClickSecondary}>
          {lang("DIALOG_LEAVE")}
        </AfButton>
      ) : undefined
    }
  >
    {children}
  </AfDialog>
);
