// Generic actions and terms

// TODO: check copy with product/design
export const notifications = {
  NOTIFICATION_STATUS_WORKFLOW_DEFINITIONS_UNABLE_TO_DISPLAY: {
    en: "Unable to display actions. Something went wrong. ",
  },
  NOTIFICATION_CREATE_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action created successfully",
  },
  NOTIFICATION_CREATE_WORKFLOW_DEFINITION_ERROR: {
    en: "Action not created. Something went wrong",
  },
  NOTIFICATION_UPDATE_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action updated successfully",
  },
  NOTIFICATION_UPDATE_WORKFLOW_DEFINITION_ERROR: {
    en: "Action not updated. Something went wrong",
  },
  NOTIFICATION_ARCHIVE_WORKFLOW_DEFINITION_ERROR: {
    en: "Unable to change archive state. Something went wrong",
  },
  NOTIFICATION_ARCHIVED_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action archived successfully.",
  },
  NOTIFICATION_UNARCHIVED_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action unarchived successfully.",
  },
  NOTIFICATION_STATUS_ACTIVE_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action published successfully",
  },
  NOTIFICATION_STATUS_INACTIVE_WORKFLOW_DEFINITION_SUCCESS: {
    en: "Action disabled successfully",
  },
  NOTIFICATION_STATUS_WORKFLOW_DEFINITION_ERROR: {
    en: "Unable to change status. Something went wrong",
  },
  NOTIFICATION_STATUS_WORKFLOW_DEFINITION_UNABLE_TO_DISPLAY: {
    en: "Unable to display action. Something went wrong. ",
  },
  NOTIFICATION_DATA_SOURCE_UNABLE_TO_DISPLAY: {
    en: "Unable to display data source options. Something went wrong. ",
  },
};
