/**
 * App (entry) options, options with no visual/UI
 * responsibility should be defined here.
 * (MFE) init options of the same scope should be included here
 */

// React
import React, { useContext } from "react";

export interface AppOptionsContextProps {
  workflowApiHost?: string;
  advisorsApiHost?: string;
  routerBaseName?: string;
}

export const AppOptionsContextDefaults: AppOptionsContextProps = {
  workflowApiHost: undefined,
  advisorsApiHost: undefined,
  routerBaseName: undefined,
};

export const AppOptionsContext =
  React.createContext<AppOptionsContextProps>(AppOptionsContextDefaults);

export const useAppOptions = (): AppOptionsContextProps => useContext(AppOptionsContext);
