// TODO: Check with BE the type for error data response
// Type should be provided by BE API.
interface ResponseError {
  message: string;
}

/**
 * Type guard to check if current `error` is `ResponseError`
 *
 * @see {@link ResponseError}
 **/
export const isResponseError = (error: unknown): error is ResponseError =>
  !!error && typeof error === "object" && "message" in error;
