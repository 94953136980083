import { createAction, createAsyncThunk, isFulfilled } from "@reduxjs/toolkit";
import { GlobalState } from "@advicefront/fe-infra-global-state";
import { StateProps } from "./types";
import { StoreState } from "@store/index";
import { initialState } from "./initial-state";
import { selectIsAuthenticated } from "./selectors";
import { waitForAuthToken } from "./utils";

export const reset = createAction("auth/reset");

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  void,
  {
    state: StoreState;
  }
>("auth/fetch", async () => {
  let authToken: StateProps["authToken"] = initialState.authToken;
  let error: StateProps["error"] = initialState.error;

  try {
    const getAuthToken = GlobalState.get<() => Promise<string>>("get-auth-token", {
      requireDefined: true,
    });
    authToken = await getAuthToken();
    if (!authToken) {
      throw new Error("Could not get authorization token");
    }
  } catch (e) {
    error = e instanceof Error ? e.message : undefined;
  }

  return {
    authToken,
    error,
  };
});

export const fetchValidToken = createAsyncThunk<
  Partial<StateProps>,
  void,
  {
    state: StoreState;
  }
>("auth/fetchValidToken", async (_, { dispatch, getState }) => {
  // getting state is a use case
  // https://redux.js.org/usage/writing-logic-thunks#thunk-use-cases
  const state = getState();
  const { authToken, loading } = state.auth;

  // if current authToken in store is valid, return it
  if (selectIsAuthenticated()(state) && authToken) {
    return {
      authToken,
      error: initialState.error,
      loading: initialState.loading,
    };
  }

  // if we are fetching a token, wait for it to resolve and return it
  if (loading) {
    let authToken: StateProps["authToken"] = initialState.authToken;
    let error: StateProps["error"] = initialState.error;

    try {
      authToken = await waitForAuthToken(getState);
    } catch (e) {
      error = e instanceof Error ? e.message : undefined;
    }

    return {
      authToken,
      error,
    };
  }

  // if we are not fetching, proxy the action to the "real one"
  const action = await dispatch(fetch());
  if (!isFulfilled(action)) {
    return {
      authToken: initialState.authToken,
      error: initialState.error,
    };
  }

  return {
    authToken: action.payload.authToken,
    error: action.payload.error,
  };
});
