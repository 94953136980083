// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Initial state
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Actions
import { reset, fetch } from "./actions";

/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get data fulfilled
    .addMatcher(isAnyOf(fetch.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading start
    .addMatcher(isAnyOf(fetch.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(isAnyOf(fetch.rejected, fetch.fulfilled), (state) => ({
      ...state,
      loading: state.loading - 1,
    }));
});
