import {
  SequenceElementType,
  ResponseWorkflowDefinitionDtoProcessInner,
} from "@advicefront/workflow-client-axios";
import { MessageStartEventDto } from "@store/modules/message-events";

/** Type guard to check if current data is MessageStartEventDto
 *
 * @see {@link MessageStartEventDto}
 **/
export const isMessageEvent = (
  data: ResponseWorkflowDefinitionDtoProcessInner
): data is MessageStartEventDto => data.__t === SequenceElementType.MessageStartEvent;
