// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import {
  CreateWorkflowDefinitionDto,
  UpdateWorkflowDefinitionDto,
  WorkflowDefinitionApiSetWorkflowDefinitionStatusRequest,
} from "@advicefront/workflow-client-axios";
import { ActionStatusHandlers } from "@store/types";
import { StateProps } from "./types";
// Initial State
import { initialState } from "./initial-state";
// Store
import { Store, StoreState } from "@store/index";
import { getAuthToken } from "../auth/utils";
// Translation
import { lang } from "@lang/index";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.workflowDefinition.reset();
 * ```
 */

export const reset = createAction("workflow-definition/reset");

/**
 * Gets one workflow definition by ID.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinition.fetch({
 *     workflowDefinitionId
 *   })
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  {
    workflowDefinitionId: string;
  },
  {
    state: StoreState;
  }
>(
  "workflow-definition/fetch-workflow-definition",
  async ({ workflowDefinitionId }, { dispatch }) => {
    let data: StateProps["data"] = initialState.data;
    let error: StateProps["error"] = initialState.error;

    try {
      const authToken = await getAuthToken(dispatch);
      const response = await API.WorkflowDefinition(authToken).findOneWorkflowDefinition({
        workflowDefinitionId,
      });

      data = response.data;
    } catch (e) {
      error = API.parseError(e);
    }

    if (error) {
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          description: lang("NOTIFICATION_STATUS_WORKFLOW_DEFINITION_UNABLE_TO_DISPLAY"),
          details: {
            type: "inline",
            message: error.message,
          },
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Creates a workflow definition.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinition.create({
 *     data: { name, description, startEventSequenceId, firmId, process },
 *   })
 * );
 * ```
 */
export const create = createAsyncThunk<
  Partial<StateProps>,
  ActionStatusHandlers & {
    data: CreateWorkflowDefinitionDto;
  },
  {
    state: StoreState;
  }
>(
  "workflow-definition/create-workflow-definition",
  async (
    { data: { name, description, firmId, startEventSequenceId }, onSuccess, onError },
    { dispatch }
  ) => {
    let error: StateProps["error"] = initialState.error;

    try {
      const authToken = await getAuthToken(dispatch);
      await API.WorkflowDefinition(authToken).createWorkflowDefinition({
        dto: {
          name,
          description: description || null,
          firmId: firmId || null,
          startEventSequenceId,
          process: [], // https://advicefront.atlassian.net/browse/PROD-2102
        },
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    if (error) {
      onError && onError();
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          description: lang("NOTIFICATION_CREATE_WORKFLOW_DEFINITION_ERROR"),
        })
      );
    }

    if (submitSuccess) {
      onSuccess && onSuccess();
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          description: lang("NOTIFICATION_CREATE_WORKFLOW_DEFINITION_SUCCESS"),
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Update a workflow definition.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinition.update({
 *     workflowDefinitionId,
 *     { name, description, startEventSequenceId, process },
 *   })
 * );
 * ```
 */
export const update = createAsyncThunk<
  Partial<StateProps>,
  ActionStatusHandlers & {
    workflowDefinitionId: string;
    data: UpdateWorkflowDefinitionDto;
  },
  {
    state: StoreState;
  }
>(
  "workflow-definition/update-workflow-definition",
  async ({ workflowDefinitionId, data, onSuccess, onError }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      const authToken = await getAuthToken(dispatch);
      await API.WorkflowDefinition(authToken).updateWorkflowDefinition({
        workflowDefinitionId,
        dto: {
          ...data,
          description: data.description || null,
        },
      });
    } catch (e) {
      error = API.parseError(e);
    }

    // refresh workflow definition
    void dispatch(Store.workflowDefinition.fetch({ workflowDefinitionId }));

    const submitSuccess = !error;

    if (error) {
      onError && onError();
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          description: lang("NOTIFICATION_UPDATE_WORKFLOW_DEFINITION_ERROR"),
        })
      );
    }

    if (submitSuccess) {
      onSuccess && onSuccess();
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          description: lang("NOTIFICATION_UPDATE_WORKFLOW_DEFINITION_SUCCESS"),
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Changes a workflow definition status.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinition.status({
 *     workflowDefinitionId,
 *     newStatus,
 *   })
 * );
 * ```
 */
export const status = createAsyncThunk<
  Partial<StateProps>,
  ActionStatusHandlers & {
    workflowDefinitionId: string;
    newStatus: WorkflowDefinitionApiSetWorkflowDefinitionStatusRequest["newStatus"];
  },
  {
    state: StoreState;
  }
>(
  "workflow-definition/status-workflow-definition",
  async ({ workflowDefinitionId, newStatus, onSuccess, onError }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      const authToken = await getAuthToken(dispatch);
      await API.WorkflowDefinition(authToken).setWorkflowDefinitionStatus({
        workflowDefinitionId,
        newStatus,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    if (error) {
      onError && onError();
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          description: lang("NOTIFICATION_STATUS_WORKFLOW_DEFINITION_ERROR"),
        })
      );
    }

    if (submitSuccess) {
      onSuccess && onSuccess();
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          description:
            newStatus === "ACTIVE"
              ? lang("NOTIFICATION_STATUS_ACTIVE_WORKFLOW_DEFINITION_SUCCESS")
              : lang("NOTIFICATION_STATUS_INACTIVE_WORKFLOW_DEFINITION_SUCCESS"),
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Changes a workflow definition archived status.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinition.archive({
 *     workflowDefinitionId,
 *   })
 * );
 * ```
 */
export const archive = createAsyncThunk<
  Partial<StateProps>,
  ActionStatusHandlers & {
    workflowDefinitionId: string;
    isArchived?: boolean;
  },
  {
    state: StoreState;
  }
>(
  "workflow-definition/archive-workflow-definition",
  async ({ workflowDefinitionId, isArchived, onSuccess, onError }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      const authToken = await getAuthToken(dispatch);
      await API.WorkflowDefinition(authToken).toggleArchivedStatus({
        workflowDefinitionId,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    if (error) {
      onError && onError();
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          description: lang("NOTIFICATION_ARCHIVE_WORKFLOW_DEFINITION_ERROR"),
        })
      );
    }

    if (submitSuccess) {
      onSuccess && onSuccess();
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          description: isArchived
            ? lang("NOTIFICATION_UNARCHIVED_WORKFLOW_DEFINITION_SUCCESS")
            : lang("NOTIFICATION_ARCHIVED_WORKFLOW_DEFINITION_SUCCESS"),
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);
