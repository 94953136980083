// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps, TaskDataSourceConfigurationDto } from "./types";
// State
import { Store, StoreState } from "@store/index";
import { getAuthToken } from "../auth/utils";
import { initialState } from "./initial-state";
// Translation
import { lang } from "@lang/index";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.dataSource.reset();
 * ```
 */

export const reset = createAction("data-source/reset");

/**
 * Get data source for service tasks.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.dataSource.fetch()
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  {
    dto: TaskDataSourceConfigurationDto;
  },
  {
    state: StoreState;
  }
>("data-source/fetch", async ({ dto }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const authToken = await getAuthToken(dispatch);
    const response = await API.WorkflowDefinition(authToken).getServiceTaskPropertyDataSource({
      dto,
    });

    data = response.data;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        description: lang("NOTIFICATION_DATA_SOURCE_UNABLE_TO_DISPLAY"),
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
