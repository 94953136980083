// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps } from "./types";
// State
import { getAuthToken } from "../auth/utils";
import { initialState } from "./initial-state";
// Store
import { Store, StoreState } from "@store/index";
// Translation
import { lang } from "@lang/index";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.workflowDefinitions.reset();
 * ```
 */

export const reset = createAction("workflow-definitions/reset");

/**
 * Fetch lists of all available workflow definitions.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.workflowDefinitions.fetch()
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  void,
  {
    state: StoreState;
  }
>("workflow-definitions/fetch-workflow-definitions", async (_, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const authToken = await getAuthToken(dispatch);
    const response = await API.WorkflowDefinition(authToken).findAllWorkflowDefinitions({
      orderBy: "updatedAt",
      orderDirection: "desc",
    });

    data = response.data.data;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        description: lang("NOTIFICATION_STATUS_WORKFLOW_DEFINITIONS_UNABLE_TO_DISPLAY"),
        details: {
          type: "inline",
          message: error.message,
        },
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
