// API
import { Advisor } from "@services/api";
// Store
import { Store, StoreState } from "@store/index";
import { ResponseWorkflowDefinitionDto } from "@store/modules/workflow-definition";
import { StateProps } from "@store/modules/workflow-definitions";

export interface WorkflowDefinition extends ResponseWorkflowDefinitionDto {
  advisor?: Advisor;
}

type SelectWorkflowDefinitions = {
  data: WorkflowDefinition[] | undefined;
  loading: StateProps["loading"];
  error: StateProps["error"];
};

/**
 * Selector is responsible to combine the information about the Workflow Definition
 * with the advisor that create/update the workflow definition.
 *
 * @returns
 * ```ts
 *  {
 *    loading: boolean, // True if data of Advisor or Workflow Definition still pending.
 *    error: string, // Error message in case of Advisor or Workflow Definition request fails.
 *    data: WorkflowDefinitions, // Workflow Definitions with name of advisor that updated last time.
 *   }
 * ```
 * @see {@link ResponseWorkflowDefinitionDto}
 * @see {@link WorkflowDefinitions}
 */
export const selectWorkflowDefinitions = Store.createSelector(
  () =>
    (state): SelectWorkflowDefinitions => {
      const loading = state.workflowDefinitions.loading || state.advisors.loading;
      const error = state.workflowDefinitions.error || state.advisors.error;
      const advisors = state.advisors.data;
      // Get all available  Workflow Definition
      const workflowDefinitions = state.workflowDefinitions.data;

      if (!workflowDefinitions) {
        return {
          loading,
          error,
          data: undefined,
        };
      }

      const data = workflowDefinitions.map((workflow) => {
        // Get the name of the Advisor, if updated is defined use the ID of `updatedBy` otherwise use the ID of `createdBy`.
        const advisor = advisors?.find(
          ({ id }) => workflow?.updatedBy?.id === id || workflow.createdBy?.id === id
        );

        return {
          ...workflow,
          advisor,
        };
      });

      return {
        loading,
        error,
        data,
      };
    }
);

/**
 * Get all Advisor IDs by mapping existing workflow definition, make sure the ID is unique to avoid extra requests to mono API.
 *
 * @returns array of advisor ID's
 */
export const selectAdvisorIds = Store.createSelector(() => (state): string[] => {
  // Get all available  Workflow Definition
  const workflowDefinitions = state.workflowDefinitions.data;

  if (!workflowDefinitions) return [];

  // Get a unique ID of Advisor, if `updatedBy` not defined, meaning that Workflow was never updated, so we should use
  // the ID of `createdBy`, otherwise use the ID of `updatedBy`, that way will reduce the number of requests required.
  return workflowDefinitions
    .map(({ updatedBy, createdBy }) => updatedBy?.id || createdBy?.id || "")
    .filter((advisors, index, array) => index === array.findIndex((i) => i === advisors));
});

/**
 * Get all Workflow definition IDs by mapping existing workflow definitions
 *
 * @returns array of workflow definition ID's
 */
export const selectWorkflowDefinitionsIds = Store.createSelector(
  () =>
    (state): string[] =>
      state.workflowDefinitions.data?.map(({ _id }) => _id) || []
);

/**
 * Get workflow definition from workflow definitions list by id.
 *
 * @returns workflow definition with desired id or undefined
 */
export const selectWorkflowDefinition =
  (id: string | undefined) =>
  (state: StoreState): WorkflowDefinition | undefined =>
    state.workflowDefinitions.data?.find((workflow) => workflow._id === id);
