// React
import React, { useEffect, useCallback, useMemo, useState } from "react";
// React Router
import { generatePath, useParams, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
// Layouts
import { DefaultLayout } from "@layouts/Default";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { ActionCard } from "@components/card";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
import { FullLoadingPage } from "@components/loading/full-page";
import { SkeletonActionCard } from "@components/loading/";
import { WorkflowDefinitionModal } from "@components/modals/workflow-definition";
// Store
import {
  WorkflowDefinitionStatus,
  ResponseWorkflowDefinitionDto,
} from "@store/modules/workflow-definition";
import {
  selectWorkflowDefinitions,
  selectAdvisorIds,
  selectWorkflowDefinition,
} from "@store/selectors/workflow-definitions";
import { Store } from "@store/index";
// Types
import { MergeWorkflowDefinition } from "@store/modules/workflow-definition";
// Routes
import { View } from "@routes/index";
import { getRoute } from "@routes/utils/get-route";
// Translations
import { lang } from "@lang/index";
import { useUniqueId } from "@advicefront/ds-base";
// Type guards
import { isCreateWorkflowDto } from "@utils/type-guards/workflow-definitions";
// Utils
import { createNumberArray } from "@utils/create-number-array";

const Element = (): React.ReactElement => {
  const dispatch = Store.useDispatch();
  const navigate = useNavigate();
  // URL params
  const { id, action } = useParams();
  // Store: Workflow Definitions
  const workflowDefinitions = Store.useSelector(selectWorkflowDefinitions());
  const selectedWorkflowDefinition = Store.useSelector(selectWorkflowDefinition(id));
  // Get Advisor ID's
  const advisorsIds = Store.useSelector(selectAdvisorIds());
  const hasError = workflowDefinitions.error;
  /**
   * When creating a workflow we need to create a startEventSequenceId that will
   * be responsible for giving a reference id for each process element. So the first
   * process element will have sequenceId as startEventSequenceId
   **/
  const startEventSequenceId = useUniqueId();

  // Initial Modal Form Data
  const initialData: MergeWorkflowDefinition = useMemo(
    () => ({
      name: "",
      startEventSequenceId,
      process: [],
    }),
    [startEventSequenceId]
  );

  // Modal Form State
  const [modalForm, setModalForm] = useState<MergeWorkflowDefinition>(initialData);

  useEffect(() => {
    void dispatch(Store.workflowDefinitions.fetch());
    // Clean any possible wf data from previous routine view navigation
    void dispatch(Store.workflowDefinition.reset());
  }, [dispatch]);

  // Fetch advisors data
  useEffect(() => {
    if (!advisorsIds.length) return;
    void dispatch(Store.advisors.fetch({ advisorsIds }));
  }, [dispatch, advisorsIds]);

  // Populate form data with workflow data when editing
  useEffect(() => {
    if (!selectedWorkflowDefinition || action !== "edit") return;
    setModalForm({
      name: selectedWorkflowDefinition.name,
      startEventSequenceId: selectedWorkflowDefinition.startEventSequenceId || "",
      process: selectedWorkflowDefinition.process || [],
    });
  }, [action, dispatch, selectedWorkflowDefinition]);

  const onHandleArchive = useCallback(
    (workflowDefinitionId: string, isArchived: boolean) => {
      if (hasError) return;

      void dispatch(
        Store.workflowDefinition.archive({
          workflowDefinitionId,
          isArchived,
          onSuccess: () => {
            // refresh
            void dispatch(Store.workflowDefinitions.fetch());
          },
        })
      );
    },
    [dispatch, hasError]
  );

  const onHandleStatusChange = useCallback(
    (
      workflowDefinitionId: ResponseWorkflowDefinitionDto["_id"],
      status: ResponseWorkflowDefinitionDto["status"]
    ) => {
      if (hasError) return;

      void dispatch(
        Store.workflowDefinition.status({
          workflowDefinitionId,
          newStatus:
            status === WorkflowDefinitionStatus.Active
              ? WorkflowDefinitionStatus.Inactive
              : WorkflowDefinitionStatus.Active,
          onSuccess: () => {
            // refresh
            void dispatch(Store.workflowDefinitions.fetch());
          },
        })
      );
    },
    [dispatch, hasError]
  );

  const workflowDefinitionsData = useMemo(
    () =>
      workflowDefinitions.data?.map(({ ...options }) => ({
        ...options,
        numberOfRunning: 0,
        onArchive: () => onHandleArchive(options._id, options.archived),
        onStatusChange: () => onHandleStatusChange(options._id, options.status),
      })),
    [workflowDefinitions.data, onHandleArchive, onHandleStatusChange]
  );

  // Close Modal and navigate workflow definition list
  const onCloseModal = useCallback((): void => {
    navigate(generatePath(getRoute("workflowDefinitions")), {
      replace: true,
    });
    setModalForm(initialData);
  }, [initialData, navigate]);

  // Submit for Create or Edit Workflow Definition
  const onHandleSubmit = useCallback(
    (data: MergeWorkflowDefinition) => {
      if (action === "create" && isCreateWorkflowDto(data)) {
        void dispatch(
          Store.workflowDefinition.create({
            data: data,
            onSuccess: () => {
              onCloseModal();

              // refresh
              void dispatch(Store.workflowDefinitions.fetch());
            },
          })
        );
        return;
      }
      if (!id) return;
      void dispatch(
        Store.workflowDefinition.update({
          workflowDefinitionId: id,
          data: data,
          onSuccess: () => {
            onCloseModal();
            // refresh
            void dispatch(Store.workflowDefinitions.fetch());
          },
        })
      );
    },
    [action, dispatch, id, onCloseModal]
  );

  return (
    <DefaultLayout>
      <AfRow justify="spread" align="center" wrap>
        <AfCol>
          <AfTypography type="h2">{lang("WORKFLOW_DEFINITIONS_TITLE")}</AfTypography>
        </AfCol>
        {workflowDefinitionsData && (
          <AfCol>
            <AfButton
              component={
                <NavLink to={generatePath(getRoute("workflowDefinitions"), { action: "create" })} />
              }
              icon={<AfIcon name="basic-add-circle" />}
            >
              {lang("WORKFLOW_DEFINITION_CREATE")}
            </AfButton>
          </AfCol>
        )}
      </AfRow>

      <>
        {!!workflowDefinitions.loading && !workflowDefinitions.data && (
          <AfRow wrap>
            {createNumberArray(4).map((_, i) => (
              <AfCol
                size={12}
                responsive={{
                  sm: {
                    size: 3,
                  },
                }}
                key={i}
              >
                <SkeletonActionCard />
              </AfCol>
            ))}
          </AfRow>
        )}
        {!!workflowDefinitions.data?.length && (
          <>
            {!!workflowDefinitions.loading && <FullLoadingPage />}
            <AfRow wrap>
              {workflowDefinitionsData?.map(({ _id, ...rest }) => (
                <AfCol
                  size={12}
                  responsive={{
                    sm: {
                      size: 3,
                    },
                  }}
                  key={_id}
                >
                  <ActionCard _id={_id} {...rest} />
                </AfCol>
              ))}
            </AfRow>
          </>
        )}
        {workflowDefinitions.data?.length === 0 && (
          <AfCard>
            <AfEmptyState
              action={
                <NavLink
                  to={generatePath(getRoute("workflowDefinitions"), { action: "create" })}
                  className="nav__link"
                >
                  <AfButton icon={<AfIcon name="basic-add-circle" />}>
                    {lang("WORKFLOW_DEFINITION_CREATE")}
                  </AfButton>
                </NavLink>
              }
              heading={lang("WORKFLOW_DEFINITIONS_NO_AVAILABLE")}
            />
          </AfCard>
        )}
      </>

      {(action === "create" || action === "edit") && (
        <WorkflowDefinitionModal
          action={action}
          onSubmit={onHandleSubmit}
          onCancel={onCloseModal}
          initialData={modalForm}
        />
      )}
    </DefaultLayout>
  );
};

export const workflowDefinitions: View = {
  name: lang("WORKFLOW_DEFINITIONS_TITLE"),
  options: {
    path: "/workflow-definitions/:action?/:id?",
    element: <Element />,
  },
};
