// React
import React, { useEffect } from "react";
// Layouts
import { LayoutProps } from "@layouts/types";
// UI Components
import { AfContainer, AfSection } from "@advicefront/ds-grid";
import { AfAlert } from "@advicefront/ds-alert";
// Hooks
import { Store } from "@store/index";
import { useGlobalNotifications } from "@hooks/global-notifications";

export const DefaultLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Notifications hook
  const { inlineNotifications } = useGlobalNotifications();

  // Authentication error handling
  const { error } = Store.useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
  }, [error]);

  return (
    <AfContainer>
      <AfSection>
        {children}
        {inlineNotifications?.map((notification) => (
          <AfAlert
            key={notification.id}
            skin={notification.type}
            title={notification.title || notification.details?.message}
            description={notification.description}
          />
        ))}
      </AfSection>
    </AfContainer>
  );
};
