import {
  SequenceElementType,
  ResponseWorkflowDefinitionDtoProcessInner,
} from "@advicefront/workflow-client-axios";
import { ServiceTaskDto } from "@store/modules/service-tasks";

/** Type guard to check if current data is ServiceTaskDto
 *
 * @see {@link ServiceTaskDto}
 **/
export const isServiceTask = (
  data: ResponseWorkflowDefinitionDtoProcessInner
): data is ServiceTaskDto => data.__t === SequenceElementType.ServiceTask;
