import { Configuration } from "./config";

export interface Advisor {
  id: string;
  email: string;
  name: string;
  object: string;
}

export interface AdvisorsWithDetails {
  count: number;
  data: Advisor[];
  object: string;
}

export class AdvisorsAPI {
  private config: Configuration;

  constructor(config: Configuration) {
    this.config = config;
  }

  public findAdvisors = async (ids: string[]): Promise<AdvisorsWithDetails> =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (await (
      await fetch(`${this.config.advisorsApiHost}/advisors/advisors_with_details`, {
        method: "POST",
        body: JSON.stringify({ advisors_ids: ids }),
        headers: {
          "Content-Type": "application/json",
          ...this.config.baseOptions.headers,
        },
      })
    ).json()) as AdvisorsWithDetails;
}
