import {
  Configuration,
  ConfigurationParameters,
  WorkflowDefinitionApi,
  HealthApi,
  WorkflowApi,
} from "@advicefront/workflow-client-axios";
import { AdvisorsAPI } from "./modules/advisor";
// Types
import { ModuleError } from "@store/types";
import axios from "axios";
// Type guard
import { isResponseError } from "@utils/type-guards/response-error";

export interface APIConfig {
  workflow: ConfigurationParameters;
  advisorsApiHost: string;
  authToken?: string;
}

export class API {
  static instance?: API;

  static advisorsAPI?: AdvisorsAPI;
  static healthApi?: HealthApi;
  static workflowDefinitionApi?: WorkflowDefinitionApi;
  static workflowApi?: WorkflowApi;

  static config: APIConfig;

  static configure = (config: Partial<APIConfig>): void => {
    API.config = { ...API.config, ...config };

    const apiConfig = new Configuration({
      ...API.config.workflow,
      baseOptions: {
        headers: {
          ...(API.config.authToken && {
            Authorization: `Bearer ${API.config.authToken}`,
          }),
        },
      },
    });

    API.advisorsAPI = new AdvisorsAPI({
      advisorsApiHost: API.config.advisorsApiHost,
      baseOptions: {
        headers: {
          ...(API.config.authToken && {
            "HTTP-ID-TOKEN": API.config.authToken,
          }),
        },
      },
    });

    API.healthApi = new HealthApi(apiConfig);
    API.workflowDefinitionApi = new WorkflowDefinitionApi(apiConfig);
    API.workflowApi = new WorkflowApi(apiConfig);
  };

  static setAuthToken = (authToken: APIConfig["authToken"]): void => {
    API.configure({
      authToken,
    });
  };

  static get WorkflowDefinition(): (authToken: APIConfig["authToken"]) => WorkflowDefinitionApi {
    return (authToken): WorkflowDefinitionApi => {
      if (!API.workflowDefinitionApi) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.workflowDefinitionApi;
    };
  }

  static get HealthApi(): (authToken: APIConfig["authToken"]) => HealthApi {
    return (authToken): HealthApi => {
      if (!API.healthApi) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.healthApi;
    };
  }

  static get AdvisorsAPI(): (authToken: APIConfig["authToken"]) => AdvisorsAPI {
    return (authToken): AdvisorsAPI => {
      if (!API.advisorsAPI) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.advisorsAPI;
    };
  }

  static get WorkflowAPI(): (authToken: APIConfig["authToken"]) => WorkflowApi {
    return (authToken): WorkflowApi => {
      if (!API.workflowApi) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.workflowApi;
    };
  }

  /**
   * Parses an error object and returns a ModuleError.
   *
   * @param e - The error object to parse.
   * @returns A ModuleError object.
   */
  static parseError(e: unknown): ModuleError {
    if (axios.isAxiosError(e)) {
      return {
        message: isResponseError(e.response?.data) ? e.response?.data.message : e.message,
        statusCode: e.response?.status,
      };
    }

    const error: ModuleError =
      e instanceof Error
        ? {
            message: e.message,
          }
        : {
            message: String(e),
          };

    return error;
  }
}

export { Advisor } from "@services/api/modules/advisor";
