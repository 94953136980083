// Styles
import "./styles/index.scss";
// React
import React from "react";
// UI Components
import { AfOverlay } from "@advicefront/ds-overlay";
import { AfSpinner } from "@advicefront/ds-spinner";
import { useClassNames } from "@advicefront/ds-base";

/**
 * This component displays Full Loading Page with overlay and Spinner.
 *
 * @example
 * ```tsx
 * <FullLoadingPage/>
 * ```
 *
 */
export const FullLoadingPage = (): React.ReactElement => {
  const rootClassName = "full-loading-page";

  return (
    <AfOverlay className={useClassNames(rootClassName)}>
      <AfSpinner />
    </AfOverlay>
  );
};
