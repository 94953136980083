// Generic actions and terms
export const serviceTasks = {
  SERVICE_TASKS_TITLE: {
    en: "Service Task",
  },
  SERVICE_TASKS_CREATE_AGREEMENT: {
    en: "Create Agreement",
  },
  SERVICE_TASKS_SEND_AGREEMENT: {
    en: "Send Agreement",
  },
  SERVICE_TASKS_CREATE_NEED_GOAL: {
    en: "Create Need Goal",
  },
  SERVICE_TASKS_CREATE_OBJECTIVE_GOAL: {
    en: "Create Objective Goal",
  },
};
