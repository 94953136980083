/// React
import React from "react";
// Layouts
import { LayoutProps } from "@layouts/types";
// UI Components
import { AfContainer } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-typography";

interface ErrorLayoutProps extends LayoutProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

export const ErrorLayout = ({
  title,
  description,
  children,
}: ErrorLayoutProps): React.ReactElement => (
  <AfContainer>
    <AfTypography type="h2">{title}</AfTypography>
    <AfTypography>{description}</AfTypography>
    {children}
  </AfContainer>
);
