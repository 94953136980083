// Generic actions and terms
export const dialog = {
  DIALOG_SAVE_BEFORE: {
    en: "Save before",
  },
  DIALOG_CONTINUE_EDITING: {
    en: "Continue editing",
  },
  DIALOG_LEAVE: {
    en: "Leave",
  },
  DIALOG_UNSAVED_CHANGES: {
    en: "You have unsaved changes. If you leave the project, all changes will be lost. Are you sure you want to continue?",
  },
};
