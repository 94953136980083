// Styles
import "./styles/index.scss";
// React
import React, { StrictMode, useMemo, useEffect } from "react";
// Router
import { DefaultRouter } from "@routes/router/Default";
// UI Components
import { AfAlert } from "@advicefront/ds-alert";
import { AfRoot } from "@advicefront/ds-base";
import { AfSnackbar } from "@advicefront/ds-snackbar";
// Utils
import { ErrorBoundary } from "@advicefront/fe-infra-error-and-logging";
import { GlobalState } from "@advicefront/fe-infra-global-state";
// Context
import { AppOptionsContext, AppOptionsContextProps } from "@context/app-options";
// Store
import { Store } from "@store/index";
// API
import { API } from "@services/api";

// Config
import { ROOT_ELEMENT_ID } from "./config-client";

const App = (): React.ReactElement => {
  const appOptions = useMemo<AppOptionsContextProps>(
    () => ({
      workflowApiHost: GlobalState.get<string>("workflow-api-host", {
        requireDefined: true,
      }),
      advisorsApiHost: GlobalState.get<string>("mono-v3-api-host", {
        requireDefined: true,
      }),
      routerBaseName: GlobalState.get<string>("actions-router-basename"),
    }),
    []
  );

  useEffect(() => {
    // Setup base api options
    API.configure({
      workflow: {
        basePath: GlobalState.get<string>("workflow-api-host", {
          requireDefined: true,
        }),
      },
      advisorsApiHost: GlobalState.get<string>("mono-v3-api-host", {
        requireDefined: true,
      }),
    });
  }, []);

  return (
    <Store.Provider>
      <AppOptionsContext.Provider value={appOptions}>
        <DefaultRouter />
      </AppOptionsContext.Provider>
    </Store.Provider>
  );
};

export const AppWrapper = (): React.ReactElement => (
  <StrictMode>
    <AfRoot
      cssScope={`${ROOT_ELEMENT_ID}-scope microfrontend-scope`}
      classNamesPrefix={`${ROOT_ELEMENT_ID}-component`}
    >
      <ErrorBoundary
        Component={(err): React.ReactElement => (
          <AfAlert skin="error" title="Error" description={err.message} />
        )}
      >
        <AfSnackbar>
          <App />
        </AfSnackbar>
      </ErrorBoundary>
    </AfRoot>
  </StrictMode>
);
