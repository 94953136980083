// Utils
import { SetupRtkStore, SetupRtk, getNotificationModule } from "@advicefront/fe-infra-redux";
// Modules
import * as advisors from "@store/modules/advisors";
import * as auth from "@store/modules/auth";
import * as dataSource from "@store/modules/data-source";
import * as health from "@store/modules/health";
import * as messageEvents from "@store/modules/message-events";
import * as serviceTasks from "@store/modules/service-tasks";
import * as workflowDefinition from "@store/modules/workflow-definition";
import * as workflowDefinitions from "@store/modules/workflow-definitions";
import * as workflowExecutions from "@store/modules/workflow-executions";
// Types
import { NotificationsDetails } from "@store/types";

// Notification System Module
const notifications = getNotificationModule<NotificationsDetails>();

/**
 * Group Redux modules for store configuration
 */
const modules = {
  advisors,
  auth,
  dataSource,
  health,
  messageEvents,
  notifications,
  serviceTasks,
  workflowDefinition,
  workflowDefinitions,
  workflowExecutions,
};

/**
 * Export store utils & Redux modules
 */
export const Store = {
  ...new SetupRtkStore({
    modules,
  }),
  ...modules,
};

/**
 * Export global store state type
 */
export type StoreState = SetupRtk.AppRootState<typeof modules>;
