// Generic actions and terms
export const workflowDefinitions = {
  WORKFLOW_DEFINITIONS_TITLE: {
    en: "Workflow Definitions",
  },
  WORKFLOW_DEFINITION_CREATE: {
    en: "Create Workflow Definition",
  },
  WORKFLOW_DEFINITION_EDIT: {
    en: "Edit Workflow Definition",
  },
  WORKFLOW_DEFINITION_ACTIVE: {
    en: "Enabled",
  },
  WORKFLOW_DEFINITION_DRAFT: {
    en: "Draft",
  },
  WORKFLOW_DEFINITION_INACTIVE: {
    en: "Disabled",
  },
  WORKFLOW_DEFINITION_ARCHIVE: {
    en: "Archive",
  },
  WORKFLOW_DEFINITION_UN_ARCHIVE: {
    en: "Unarchive",
  },
  WORKFLOW_DEFINITION_UPDATED_BY: {
    en: "Last updated",
  },
  WORKFLOW_DEFINITION_CREATED_UPDATED_AT: {
    en: "at {0} | {1}",
  },
  WORKFLOW_DEFINITION_RUN_EXECUTED: {
    en: "{0} run executed",
  },
  WORKFLOW_DEFINITIONS_NO_AVAILABLE: {
    en: "No Workflow Definitions available!",
  },
};
