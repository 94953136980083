import React from "react";
// Types
import { TaskFormProps } from "@components/form";

/**
 * Checks if the provided element is a valid React element with props of type TaskFormProps.
 *
 * @param element - The element to be checked.
 * @returns A boolean indicating whether the element is a valid TaskForm element.
 */
export const isTaskForm = (element: unknown): element is React.ReactElement<TaskFormProps> =>
  !!(React.isValidElement(element) && element.props);
