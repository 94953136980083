// Generic actions and terms
export const workflowDefinition = {
  WORKFLOW_DEFINITION_MODAL_HEADER_CREATE: {
    en: "New Action",
  },
  WORKFLOW_DEFINITION_MODAL_HEADER_EDIT: {
    en: "Edit",
  },
  WORKFLOW_DEFINITION_DETAILS: {
    en: "Workflow Definition Details",
  },
  WORKFLOW_DEFINITION_NOT_EXISTS: {
    en: "Workflow Definitions with ID: {0} not exists!",
  },

  WORKFLOW_ROUTINE_START_CHIP: {
    en: "Start of workflow",
  },

  WORKFLOW_ROUTINE_END_CHIP: {
    en: "End of workflow",
  },
};
