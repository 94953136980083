// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps } from "./types";
// State
import { StoreState } from "@store/index";
import { getAuthToken } from "../auth/utils";
import { initialState } from "./initial-state";

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.advisor.reset();
 * ```
 */

export const reset = createAction("advisor/reset");

/**
 * Get all information about advisors.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.advisor.fetch({
 *     advisorsIds,
 *   })
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  {
    advisorsIds: string[];
  },
  {
    state: StoreState;
  }
>("advisor/fetch", async ({ advisorsIds }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const authToken = await getAuthToken(dispatch);
    const response = await API.AdvisorsAPI(authToken).findAdvisors(advisorsIds);
    data = response.data;
  } catch (e) {
    error = API.parseError(e);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
