// Styles
import "./styles/index.scss";
// React
import React, { useRef, useState, useCallback } from "react";
// UI Components
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfDropdown } from "@advicefront/ds-dropdown";
import { AfIcon } from "@advicefront/ds-icon";
// Translations
import { lang } from "@lang/index";
// Utils
import { useClassNames } from "@advicefront/ds-base";

interface HeaderProps {
  /** The content for the title of the header. */
  title?: string;
  /** Set it's the workflow definition its in archived or unarchived. */
  isArchived?: boolean;
  /** The current state of workflow definition. */
  isPublished?: boolean;
  /** Callback fired when triggered back button. */
  onBack?: () => void;
  /** Callback fired when archive/unarchived the workflow definition. */
  onArchive?: () => void;
  /** Callback fired when triggered publish action. */
  onPublish?: () => void;
}

/**
 * This component displays Header.
 * Header component is responsible to show the name of Workflow Definition, also options such "Go Back", "Publish" and "Archive".
 *
 * @example
 * ```tsx
 * <Header title="Client Registered" isArchived={false} isPublished />
 * ```
 *
 * @param props - HeaderProps
 */
export const Header = ({
  title,
  isArchived,
  isPublished,
  onBack,
  onArchive,
  onPublish,
}: HeaderProps): React.ReactElement => {
  const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const rootClassName = "header";
  const cssClasses = {
    root: useClassNames(rootClassName),
    content: useClassNames(`${rootClassName}__content`),
    title: useClassNames(`${rootClassName}__title`),
    options: useClassNames(`${rootClassName}__options`),
  };

  const onHandleArchive = useCallback((): void => {
    if (onArchive) onArchive();
    setDropdownOpen(false);
  }, [onArchive]);

  return (
    <header className={cssClasses.root}>
      <div className={cssClasses.content}>
        <div>
          <AfButton
            size="s"
            skin="ghost"
            icon={<AfIcon name="basic-arrow-tail-left" />}
            onClick={(): void => {
              if (onBack) onBack();
            }}
          >
            {lang("FORMS_BACK")}
          </AfButton>
        </div>
        <div className={cssClasses.title}>{title}</div>
        <div className={cssClasses.options}>
          <AfButton
            size="s"
            icon={<AfIcon name="basic-play" />}
            disabled={isArchived || isPublished || !onPublish}
            onClick={onPublish}
          >
            {isPublished ? lang("FORMS_PUBLISHED") : lang("FORMS_PUBLISH")}
          </AfButton>
          <AfButton
            ref={dropdownBtnRef}
            size="s"
            icon={<AfIcon name="basic-more" />}
            disabled={isPublished || !onArchive}
            skin="secondary"
            onClick={(): void => setDropdownOpen(!isDropdownOpen)}
          />
          <AfDropdown
            isOpen={isDropdownOpen}
            anchorRef={dropdownBtnRef}
            onClose={(): void => setDropdownOpen(false)}
          >
            <AfActionList>
              <AfActionList.Section>
                <AfActionList.Item
                  leftNode={
                    <AfIcon
                      name={isArchived ? "arrow-back-square" : "essentional-archive"}
                      size="xs"
                    />
                  }
                  onClick={onHandleArchive}
                >
                  {isArchived
                    ? lang("WORKFLOW_DEFINITION_UN_ARCHIVE")
                    : lang("WORKFLOW_DEFINITION_ARCHIVE")}
                </AfActionList.Item>
              </AfActionList.Section>
            </AfActionList>
          </AfDropdown>
        </div>
      </div>
    </header>
  );
};
