// React
import React, { useState, useCallback, useEffect } from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfAvatar } from "@advicefront/ds-avatar";
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfSelect } from "@advicefront/ds-select";
import { AfTextField } from "@advicefront/ds-text-field";
import { RoutineCard } from "@components/card";
// Translations
import { lang } from "@lang/index";
// Assets
import ConditionIconURL from "@assets/icon/condition.svg?url";

// TODO: add operator type for form data
export interface ConditionCallback {
  select1?: string;
  select2?: string;
  value?: string;
}

interface ConditionFormProps {
  /** Check is action status is Active/Published */
  isWorkflowDefinitionActive: boolean;
  /** Callback fired when click in button save. */
  onSave: (conditions: ConditionCallback) => void;
  /** Callback fired when the user commits a value change, also return if exists data to save. */
  onChange?: (options: { isFormDataSaved: boolean }) => void;
  /** Callback fired when triggered delete action. */
  onDelete?: (id: string) => void;
}

// TODO: ADD DOCS
/**
 * This Condition component displays `<form />` to create/edit conditions.
 *
 * @example
 * ```tsx
 * <ConditionForm onSave={onHandleSave} isWorkflowDefinitionActive={true} />
 * ```
 *
 * @param props -  ConditionFormProps
 */
export const ConditionForm = ({
  isWorkflowDefinitionActive,
  onSave,
  onChange,
}: ConditionFormProps): React.ReactElement => {
  // TODO: add operator type for form data
  const [formData, setFormData] = useState<ConditionCallback>({});

  // TODO ADD form data saved
  // In case of form field is not empty or new values are the sames as previous, will set hidden state in button save.
  const isFormDataSaved = false;

  const onHandleClean = useCallback(() => {
    setFormData({});
  }, []);

  const onHandleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { name, value } = event.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const onHandleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      onSave(formData);
    },
    [formData, onSave]
  );

  useEffect(() => {
    if (onChange) onChange({ isFormDataSaved });
  }, [onChange, isFormDataSaved]);

  // TODO: Add conditional "and" incrementals
  return (
    <form onSubmit={onHandleSubmit}>
      <RoutineCard
        title={"Condition"}
        icon={<AfAvatar size="s" nativeImgProps={{ src: ConditionIconURL }} />}
        isSubmitHidden={isFormDataSaved}
        isDropdownDisabled={isWorkflowDefinitionActive}
        onClean={onHandleClean}
      >
        <AfRow orientation="horizontal" align="end" wrap>
          <AfCol size={1}>
            <AfSelect
              disabled={isWorkflowDefinitionActive}
              label={lang("FORMS_LABEL_CONDITION_AND")}
              placeholder={lang("FORMS_PLACEHOLDER_CONDITION_SELECT")}
              value={formData.select1}
              onChange={(value): void => {
                setFormData({ ...formData, select1: value });
              }}
              options={[{ label: "", value: " " }]}
              required
            />
          </AfCol>
          <AfCol size={1}>
            <AfSelect
              label={" "}
              placeholder={lang("FORMS_PLACEHOLDER_CONDITION_OPERATOR")}
              disabled={isWorkflowDefinitionActive}
              value={formData.select2}
              onChange={(value): void => {
                setFormData({ ...formData, select2: value });
              }}
              options={[{ label: "", value: " " }]}
              required
            />
          </AfCol>
          <AfCol size={3}>
            <AfTextField
              label={" "}
              placeholder={lang("FORMS_PLACEHOLDER_CONDITION_VALUE")}
              name={"value"}
              disabled={!!isWorkflowDefinitionActive}
              value={formData.value}
              onChange={onHandleChange}
              required={true}
            />
          </AfCol>
          <AfCol size={1}>
            <AfButton
              type="button"
              icon={<AfIcon name="essentional-minus-square" />}
              skin="secondary"
            />
          </AfCol>
        </AfRow>
        <AfRow>
          <AfCol>
            <AfButton type="button" icon={<AfIcon name="basic-add-circle" />} skin="secondary">
              {lang("FORMS_BUTTON_CONDITION_AND")}
            </AfButton>
          </AfCol>
        </AfRow>
      </RoutineCard>
    </form>
  );
};
