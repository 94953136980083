// React
import React, { useCallback, useEffect, useState, useId } from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfButton } from "@advicefront/ds-button";
import { AfModal } from "@advicefront/ds-modal";
import { AfTextField } from "@advicefront/ds-text-field";
// Store
import { MergeWorkflowDefinition } from "@store/modules/workflow-definition";

// Translations
import { lang } from "@lang/index";

/**
 *  Modal for Creating a Workflow or Editing Workflow Name.
 */

interface WorkflowDefinitionModalProps<T> {
  /** modal actions */
  action: "create" | "edit";
  /** data to populate form when opening modal */
  initialData: T;
  /** submit handler */
  onSubmit: (data: T) => void;
  /** cancel handler */
  onCancel: () => void;
}

export const WorkflowDefinitionModal = <T extends MergeWorkflowDefinition>({
  action,
  onSubmit,
  onCancel,
  initialData,
}: WorkflowDefinitionModalProps<T>): React.ReactElement => {
  // Form Id
  const formId = useId();
  // Local state
  const [formData, setFormData] = useState<T>(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const onHandleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      onSubmit(formData);
    },
    [formData, onSubmit]
  );

  return (
    <AfModal size="s" spacing="s">
      <AfModal.Header
        border={false}
        heading={
          action === "create"
            ? lang("WORKFLOW_DEFINITION_MODAL_HEADER_CREATE")
            : lang("WORKFLOW_DEFINITION_MODAL_HEADER_EDIT")
        }
      />
      <AfModal.Content hasScrollableContainer={false}>
        <form onSubmit={onHandleSubmit} id={formId}>
          <AfRow wrap>
            <AfCol size={12}>
              <AfTextField
                name="name"
                placeholder={lang("FORMS_PLACEHOLDER_TEXT", lang("FORMS_NAME").toLowerCase())}
                value={formData?.name}
                onChange={(e): void => {
                  const { value } = e.target;
                  setFormData((data) => ({
                    ...data,
                    name: value,
                  }));
                }}
                autoComplete="off"
                required
              />
            </AfCol>
          </AfRow>
        </form>
      </AfModal.Content>
      <AfModal.Footer
        border={false}
        actions={[
          <AfButton key="cancel-delete" skin="ghost" onClick={onCancel}>
            {lang("FORMS_CANCEL")}
          </AfButton>,
          <AfButton
            disabled={!formData.name || formData.name === initialData.name}
            key="confirm-delete"
            skin="primary"
            type="submit"
            form={formId}
          >
            {action === "create" ? lang("FORMS_CREATE") : lang("FORMS_SAVE")}
          </AfButton>,
        ]}
      />
    </AfModal>
  );
};
