// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps } from "./types";
// State
import { StoreState } from "@store/index";
import { getAuthToken } from "../auth/utils";
import { initialState } from "./initial-state";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.messageEvents.reset();
 * ```
 */

export const reset = createAction("message-events/reset");

/**
 * Fetch lists of all message events.
 * Message events are events which reference a message; they are used to wait until a proper message is received.
 *
 * @see {@link https://docs.camunda.io/docs/components/modeler/bpmn/message-events/}
 * @example
 * ```ts
 * void dispatch(
 *   Store.messageEvents.fetch()
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  void,
  {
    state: StoreState;
  }
>("message-events/fetch", async (_, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const authToken = await getAuthToken(dispatch);
    const response = await API.WorkflowDefinition(authToken).getMessageEvents();
    data = response.data;
  } catch (e) {
    error = API.parseError(e);
  }

  return {
    data,
    error,
  };
});
