// Styles
import "./styles/index.scss";
// React
import React, { useRef, useState, useMemo, useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
// Routes
import { getRoute } from "@routes/utils/get-route";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfDropdown } from "@advicefront/ds-dropdown";
import { AfIcon } from "@advicefront/ds-icon";
import { AfToggle } from "@advicefront/ds-toggle";
import { AfTypography } from "@advicefront/ds-typography";
// Store
import { WorkflowDefinition } from "@store/selectors/workflow-definitions";
import { WorkflowDefinitionStatus } from "@store/modules/workflow-definition";
// Utils
import { formatDate } from "@utils/date";
import { useClassNames } from "@advicefront/ds-base";
// Translations
import { lang } from "@lang/index";
// Constants
import { STATUS_TYPE } from "@constants/index";

export interface ActionCardProps extends WorkflowDefinition {
  /** Callback on toggle archive status. */
  onArchive?: () => void;
  /** Callback fired when enable or disabled the workflow definition. */
  onStatusChange?: () => void;
}

/**
 * This component displays workflow definition card .
 *
 * @example
 * ```tsx
 * <ActionCard id="123" title="Client Registered" numberOfRuns={0} status="INACTIVE" />
 * ```
 *
 * @param props - ActionCardProps
 */
export const ActionCard = ({
  _id: id,
  name,
  totalExecutions,
  status,
  archived,
  createdAt,
  updatedAt,
  advisor,
  onArchive,
  onStatusChange,
}: ActionCardProps): React.ReactElement => {
  const params = useParams();
  const navigate = useNavigate();
  const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isActive = status === WorkflowDefinitionStatus.Active;
  const rootClassName = "card-action";
  const cssClasses = {
    root: useClassNames(rootClassName, {
      "is-active": isActive,
    }),
    title: useClassNames(`${rootClassName}__title`),
    status: useClassNames(`${rootClassName}__status`),
  };
  const { date, hour } = useMemo(() => formatDate(updatedAt || createdAt), [updatedAt, createdAt]);

  const onHandleCardClick = (): void => {
    navigate(
      generatePath(getRoute("workflowDefinitionDetails"), {
        ...params,
        id,
      })
    );
  };

  const onHandleArchive = useCallback((): void => {
    if (onArchive) onArchive();
    setDropdownOpen(false);
  }, [onArchive]);

  const onToggleChange = useCallback((): void => {
    if (onStatusChange) onStatusChange();
  }, [onStatusChange]);

  return (
    <AfCard
      border={false}
      className={cssClasses.root}
      onClick={onHandleCardClick}
      onKeyDown={(ev): void => {
        ev.key === "Enter" && onHandleCardClick();
      }}
    >
      <AfCard.Header>
        <AfTypography
          className={cssClasses.title}
          skin={status === WorkflowDefinitionStatus.Active ? "text" : "text-light"}
          type="h4"
        >
          {name}
        </AfTypography>
      </AfCard.Header>
      <AfRow>
        <AfCol>
          <AfTypography type="small" hasMargin={false}>
            {lang("WORKFLOW_DEFINITION_UPDATED_BY")}{" "}
            <AfTypography type="small" skin="brand-primary" component={<span />}>
              {advisor?.name}
            </AfTypography>
          </AfTypography>
          <AfTypography type="extra-small" skin="text-light" hasMargin={false}>
            {lang("WORKFLOW_DEFINITION_CREATED_UPDATED_AT", [date, hour])}
          </AfTypography>
          <AfTypography type="small" hasMargin={false}>
            {lang("WORKFLOW_DEFINITION_RUN_EXECUTED", totalExecutions)}
          </AfTypography>
        </AfCol>
      </AfRow>
      <AfCard.Footer>
        <AfRow align="center" justify="spread" spacing="none">
          <AfCol>
            {status === WorkflowDefinitionStatus.Draft || archived ? (
              <AfTypography type="small" skin="text-light" hasMargin={false}>
                {archived ? lang("WORKFLOW_DEFINITION_ARCHIVE") : STATUS_TYPE[status]}
              </AfTypography>
            ) : (
              <AfToggle
                label={STATUS_TYPE[status]}
                size="sm"
                nativeProps={{ className: cssClasses.status }}
                nativeInputProps={{
                  onClick: (e): void => e.stopPropagation(),
                  onKeyDown: (e): void => {
                    e.stopPropagation();
                    e.key === "Enter" && onToggleChange();
                  },
                  onChange: (): void => onToggleChange(),
                  checked: status === WorkflowDefinitionStatus.Active,
                }}
              />
            )}
          </AfCol>
          <AfCol>
            <AfButton
              ref={dropdownBtnRef}
              size="s"
              icon={<AfIcon name="basic-more" />}
              skin="ghost"
              disabled={isActive}
              onKeyDown={(e): void => e.stopPropagation()}
              onClick={(e): void => {
                e.stopPropagation();
                setDropdownOpen(!isDropdownOpen);
              }}
            />
            <AfDropdown
              isOpen={isDropdownOpen}
              anchorRef={dropdownBtnRef}
              onClose={(): void => setDropdownOpen(false)}
            >
              <AfActionList>
                <AfActionList.Item
                  leftNode={<AfIcon name="content-edit-line" size="xs" />}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                    e.stopPropagation();
                    navigate(
                      generatePath(getRoute("workflowDefinitions"), { action: "edit", id: id })
                    );
                    setDropdownOpen(false);
                  }}
                >
                  {lang("WORKFLOW_DEFINITION_EDIT")}
                </AfActionList.Item>
                {onHandleArchive && (
                  <AfActionList.Item
                    leftNode={
                      <AfIcon
                        name={archived ? "arrow-back-square" : "essentional-archive"}
                        size="xs"
                      />
                    }
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                      e.stopPropagation();
                      onHandleArchive();
                    }}
                  >
                    {archived
                      ? lang("WORKFLOW_DEFINITION_UN_ARCHIVE")
                      : lang("WORKFLOW_DEFINITION_ARCHIVE")}
                  </AfActionList.Item>
                )}
              </AfActionList>
            </AfDropdown>
          </AfCol>
        </AfRow>
      </AfCard.Footer>
    </AfCard>
  );
};
