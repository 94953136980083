// Generic actions and terms
export const messageEvents = {
  MESSAGE_EVENTS_TITLE: {
    en: "Message Event",
  },
  MESSAGE_EVENTS_CLIENT_CREATED: {
    en: "Client Created",
  },
  MESSAGE_EVENTS_CLIENT_REGISTERED: {
    en: "Client Registered",
  },
  MESSAGE_EVENTS_GOAL_CREATED: {
    en: "Goal Created",
  },
  MESSAGE_EVENTS_ACCOUNT_CREATED: {
    en: "Account Created",
  },
  MESSAGE_EVENTS_PROTECTION_CREATED: {
    en: "Protection Created",
  },
};
