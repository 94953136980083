// Styles
import "./styles/index.scss";
// React
import React, { useState, useMemo } from "react";
// Components
import { AfCol, AfRow, AfSection } from "@advicefront/ds-grid";
import { AfChip } from "@advicefront/ds-chip";
import { RoutineElement } from "./RoutineElement";
// Utils
import { useClassNames } from "@advicefront/ds-base";
// Translation
import { lang } from "@lang/index";
// Types
import { ActionButtonProps } from "@components/button";
import { AfIconProps } from "@advicefront/ds-icon";
// Type guards
import { isTaskForm } from "@utils/type-guards/service-task-form";

/**
 * Possible nodes that can be used on Routine Builder
 */
export interface NodeTypes {
  /** Node type name to be identified on incrementor */
  type: string;
  /** Icon name to be displayed on incrementor */
  icon?: AfIconProps["name"];
  /** Skin color to be displayed on incrementor */
  skin?: ActionButtonProps["skin"];
  /** The component to be used as incremental element with its removal cb and index */
  renderNode: (index: number, onRemoveIncremental?: () => void) => React.ReactElement;
}

/**
 * Define Incremental to be added
 */
export interface IncrementalType {
  index: number;
  type: NodeTypes["type"];
}

interface RoutineBuilderProps {
  /** Possible nodes that can be used on Routine Builder */
  nodeTypes?: NodeTypes[];
  /** Content to be displayed in the routine builder. */
  children?: React.ReactNode | React.ReactNode[];
  /** enable/disable incrementing */
  incrementDisabled?: boolean;
}

/**
 * This component displays the routine builder with the desired incremental element.
 *
 * @example
 * ```tsx
 * <RoutineBuilder 
 * incrementedDisabled={true} 
 * nodeTypes={[{
      type: "message",
      renderNode: (removeIncremental, index) => (
        <div>
          task {index}
          <button onClick={removeIncremental}>X</button>
        </div>
      )]}>
 *   <div>example 2</div>
 *   <div>example 3</div>
 * </RoutineCard>
 * ```
 *
 * @param props - RoutineBuilderProps
 */

export const RoutineBuilder = ({
  children,
  incrementDisabled,
  nodeTypes,
}: RoutineBuilderProps): React.ReactElement => {
  const rootClassName = "routine-builder";
  const cssClasses = {
    startChip: useClassNames(`${rootClassName}__start-chip`),
  };
  const computedChildren = useMemo(() => React.Children.toArray(children), [children]);
  /** Control incremental type to be added */
  const [incrementalType, setIncrementalType] = useState<IncrementalType | undefined>(undefined);
  /** elements to be displayed on routine builder with new ones when incrementing */
  const routineElements = useMemo(() => {
    const computedState = [...computedChildren];

    if (!incrementalType?.index) return computedChildren;
    const incremental = nodeTypes
      ?.find((nodeType) => nodeType.type === incrementalType?.type)
      ?.renderNode(incrementalType?.index, () => setIncrementalType?.(undefined));
    if (incremental) {
      computedState.splice(incrementalType?.index, 0, incremental);
    }

    return computedState;
  }, [computedChildren, incrementalType, nodeTypes, setIncrementalType]);

  return (
    <AfSection>
      <AfRow orientation="vertical" align="center" spacing="none">
        <AfCol>
          <AfChip disabled className={cssClasses.startChip}>
            {lang("WORKFLOW_ROUTINE_START_CHIP")}
          </AfChip>
        </AfCol>
      </AfRow>
      {React.Children.map(routineElements, (child, index) => {
        if (!React.isValidElement(child)) return;
        const key = isTaskForm(child) ? child.props.id : index;

        return (
          <RoutineElement
            key={`routine-builder-${key}`}
            nodeTypes={nodeTypes}
            incrementDisabled={incrementDisabled}
            incrementalType={incrementalType}
            onClick={(type: string): void => {
              setIncrementalType?.({
                index: index + 1,
                type: type,
              });
            }}
          >
            {child}
          </RoutineElement>
        );
      })}
      <AfRow orientation="vertical" align="center">
        <AfCol>
          <AfChip disabled>{lang("WORKFLOW_ROUTINE_END_CHIP")}</AfChip>
        </AfCol>
      </AfRow>
    </AfSection>
  );
};
