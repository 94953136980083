// React
import React, { useState, useCallback, useMemo, useEffect } from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfSelect, AfSelectProps } from "@advicefront/ds-select";
import { AfAvatar } from "@advicefront/ds-avatar";
import { AfCheckbox } from "@advicefront/ds-checkbox";
import { AfSpinner } from "@advicefront/ds-spinner";
import { AfTextField } from "@advicefront/ds-text-field";
import { RoutineCard } from "@components/card";
import { useUniqueId } from "@advicefront/ds-base";
// Store
import { ServiceTaskDefinitionDto } from "@store/modules/service-tasks";
import { TaskPropertyDataSourceItemDto } from "@store/modules/data-source/types";
// Translations
import { lang } from "@lang/index";
// Constants
import { TASKS_TYPE } from "@constants/index";
// Assets
import TaskIconURL from "@assets/icon/task.svg?url";
// Type guards
import { isString, isBoolean } from "@utils/type-guards/predicate";
import { isDataSource } from "@utils/type-guards/data-source";
// Utils
import { readableString } from "@utils/format";

// Represents a set of select options for a dropdown.
type SelectOptions = {
  /** The key-value pairs defining the options for the dropdown. */
  [key: string]: AfSelectProps["options"];
};

// Represents a callback function used in a task form.
export type TaskFormCallback = {
  /** The ID of the task form callback. */
  id: string;
  /** The type of task associated with the callback. */
  taskType: string;
  /** Optional headers for the task form. */
  headers?: TaskFormProps["headers"];
};

export interface TaskFormProps {
  /** Unique identifier service task. */
  id?: string;
  /** Check is action status is Active/Published */
  isWorkflowDefinitionActive?: boolean;
  /** Set the selected service tasks. */
  selectedTaskType?: string;
  /** List of service tasks.  */
  serviceTasks?: ServiceTaskDefinitionDto[];
  /** Array of field keys and values. */
  headers?: Record<string, string | boolean>;
  /** Array of options to be populated inside select, depending of options selected. */
  dataSourceOptions?: Record<string, TaskPropertyDataSourceItemDto> | undefined;
  /** Callback fired when triggered delete action. */
  onDelete?: (id: TaskFormCallback["id"]) => void;
  /** Callback fired when click in button save. */
  onSave: (options: TaskFormCallback) => void;
  /** Callback fired when the advisor commits a value change, also return if exists data to save. */
  onChange?: (options: { isFormDataSaved: boolean }) => void;
  /** Callback fired when the advisor change the `taskType` to request the `dataSourceOptions`.  */
  onChangeTaskType?: (taskType: string) => void;
}

/**
 * This TaskForm component displays `<form/>` to create/edit service task.
 * A service task represents a work item in the process with a specific type.
 *
 * @see {@link https://docs.camunda.io/docs/components/modeler/bpmn/service-tasks/}
 *
 * @example
 * ```tsx
 * <TaskForm onSave={onHandleSave} />
 * ```
 *
 * @param props - TaskFormProps
 */
export const TaskForm = ({
  id,
  isWorkflowDefinitionActive,
  selectedTaskType,
  serviceTasks,
  headers,
  dataSourceOptions,
  onSave,
  onDelete,
  onChange,
  onChangeTaskType,
}: TaskFormProps): React.ReactElement => {
  const uniqueId = useUniqueId();
  const taskId = id || uniqueId;

  // Set initial values, will used to populate the form fields and also to compare new values with previous.
  const initialValue = useMemo<Record<string, string | boolean>>(
    () =>
      selectedTaskType
        ? {
            taskType: selectedTaskType,
            ...headers,
          }
        : { ...headers },
    [headers, selectedTaskType]
  );

  const [formData, setFormData] = useState<typeof initialValue>(initialValue);

  // If the form was already submitted, the length of the object should have in count the `targetRef`
  // otherwise we only need to ensure the object is empty.
  const isEmptyForm = useMemo(
    () => (id ? Object.keys(formData).length === 1 : Object.keys(formData).length === 0),
    [formData, id]
  );

  // In case of form field is not empty or new values are the sames as previous, will set hidden state in button save.
  const isFormDataSaved = useMemo(() => {
    if (Object.keys(initialValue).length === 0) return isEmptyForm;
    // condition required because some form elements can be hidden depending of selected options.
    if (Object.keys(initialValue).length !== Object.keys(formData).length) return false;
    return !Object.keys(initialValue).some((key) => initialValue[key] !== formData[key]);
  }, [formData, initialValue, isEmptyForm]);

  const taskTypeOptions = useMemo(
    () =>
      serviceTasks?.map(({ taskType }) => ({
        label: TASKS_TYPE[taskType],
        value: taskType,
      })),
    [serviceTasks]
  );

  const taskHeaderOptions = useMemo(
    () =>
      Object.entries(
        serviceTasks?.find(
          (option): option is ServiceTaskDefinitionDto => option.taskType === formData.taskType
        )?.headers || []
      ),
    [formData.taskType, serviceTasks]
  );

  const selectOptions = useMemo<SelectOptions>(
    () =>
      taskHeaderOptions.reduce((acc, [name, options]) => {
        if (!isDataSource(options)) return acc;

        const { allowedValues, dataSourceType, childOf } = options;

        // If `allowedValues` defined, we just need to format the output to select options type.
        if (allowedValues) {
          const formattedValues = allowedValues.map((value) => ({
            label: isString(value) ? readableString(value) : value,
            value,
          }));
          return { ...acc, [name]: formattedValues };
        }

        // Format options depended of `dataSourceType`,
        // also check if options contains a `childOf` to not showing options that belongs to a different parent.
        if (dataSourceType && dataSourceOptions && dataSourceOptions[dataSourceType]) {
          const data = Object.values(dataSourceOptions[dataSourceType]);
          const filterCondition = isString(childOf) && formData[childOf];

          const formattedValues = data
            .filter(
              (opt: TaskPropertyDataSourceItemDto) =>
                !filterCondition || formData[childOf] === opt.childOf
            )
            .map(({ label, id }: TaskPropertyDataSourceItemDto) => ({
              label,
              value: id,
            }));

          return { ...acc, [name]: formattedValues };
        }

        return acc;
      }, {}),
    [dataSourceOptions, formData, taskHeaderOptions]
  );

  const onHandleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value, checked, type } = event.target;
    if (type === "checkbox") {
      setFormData((prev) => ({ ...prev, [name]: checked }));
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onHandleClean = useCallback(() => {
    // we need to keep the `targetRef` value, if the services were already created,
    // to keep a reference of the task when submits.
    setFormData(id ? { targetRef: id } : {});
  }, [id]);

  const onHandleDelete = useCallback(() => {
    if (onDelete) onDelete(taskId);
    // update `isFormDataSaved` to inform that form was deleted and avoid displaying confirmation dialog on back
    if (onChange) onChange({ isFormDataSaved: true });
  }, [onChange, onDelete, taskId]);

  const onHandleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      const { taskType, ...headers } = formData;

      onSave({
        taskType: isString(taskType) ? taskType : "",
        id: taskId,
        headers,
      });
    },
    [formData, onSave, taskId]
  );

  useEffect(() => {
    if (onChange) onChange({ isFormDataSaved });
  }, [onChange, isFormDataSaved]);

  useEffect(() => {
    const taskType = formData.taskType;

    if (onChangeTaskType && isString(taskType)) onChangeTaskType(taskType);
  }, [onChangeTaskType, formData]);

  const renderFormElement = useCallback(
    (
      fieldType: string,
      props: { name: string; label: string; value: string; disabled: boolean; required?: boolean },
      parentOf?: string | null
    ) => {
      switch (fieldType) {
        case "single-selection": {
          return (
            <AfCol key={props.name}>
              <AfSelect
                {...props}
                disabled={props.disabled}
                placeholder={lang("FORMS_PLACEHOLDER_SELECT", props.label)}
                leftNode={!selectOptions[props.name] ? <AfSpinner size="xs" /> : undefined}
                options={selectOptions[props.name]}
                onChange={(value): void => {
                  setFormData((prev) => {
                    const copy = { ...prev, [props.name]: value || "" };

                    if (isString(parentOf)) {
                      delete copy[parentOf];
                    }

                    return copy;
                  });
                }}
              />
            </AfCol>
          );
        }
        case "boolean": {
          const checked = formData[props.name];
          return (
            <AfCol key={props.name}>
              <AfCheckbox
                {...props}
                variation="boxed"
                checked={isBoolean(checked) ? checked : false}
                onChange={onHandleChange}
              >
                {props.label}
              </AfCheckbox>
            </AfCol>
          );
        }
        case "text": {
          return (
            <AfCol key={props.name}>
              <AfTextField
                {...props}
                placeholder={lang("FORMS_PLACEHOLDER_TEXT", props.label)}
                onChange={onHandleChange}
              />
            </AfCol>
          );
        }
        default: {
          // For MVP we not handle other type of `fieldType`
          return (
            <AfCol key={props.name}>
              <AfTextField
                {...props}
                placeholder={lang("FORMS_PLACEHOLDER_TEXT", props.label)}
                disabled
                readOnly
              />
            </AfCol>
          );
        }
      }
    },
    [formData, onHandleChange, selectOptions]
  );

  return (
    <form onSubmit={onHandleSubmit}>
      <RoutineCard
        title={lang("SERVICE_TASKS_TITLE")}
        icon={<AfAvatar size="s" nativeImgProps={{ src: TaskIconURL }} />}
        isSubmitHidden={isFormDataSaved}
        isDropdownDisabled={!!isWorkflowDefinitionActive}
        onClean={!isEmptyForm ? onHandleClean : undefined}
        onDelete={onHandleDelete}
      >
        <AfRow orientation="vertical">
          <AfCol>
            <AfSelect
              name="taskType"
              label={lang("FORMS_SERVICE_TASKS")}
              placeholder={lang("FORMS_PLACEHOLDER_SERVICE_TASKS")}
              disabled={!!isWorkflowDefinitionActive}
              value={isString(formData.taskType) ? formData.taskType : ""}
              onChange={(taskType): void => {
                setFormData((prev) => ({ ...prev, taskType }));
              }}
              options={taskTypeOptions}
              required
            />
          </AfCol>
        </AfRow>
        <AfRow orientation="vertical">
          {taskHeaderOptions.map(([name, options]) => {
            if (!isDataSource(options)) return;

            const { fieldType, required, childOf, parentOf, dependsOnParentValues } = options;
            const value = formData[name] || "";
            const commonProps = {
              label: readableString(name),
              name,
              value: isString(value) ? value : "",
              disabled: !!isWorkflowDefinitionActive,
              required: !!required,
            };

            // Hide form element if it depends on another element being filled or if it is filled with a specific value.
            if (
              (isString(childOf) && !formData[childOf]) ||
              (isString(childOf) &&
                formData[childOf] &&
                dependsOnParentValues &&
                !dependsOnParentValues.includes(formData[childOf]))
            ) {
              return;
            }

            return renderFormElement(fieldType, commonProps, parentOf);
          })}
        </AfRow>
      </RoutineCard>
    </form>
  );
};
