import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { reset, fetch, fetchValidToken } from "./actions";
import { StateProps } from "./types";
import { initialState } from "./initial-state";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Store a new token
    .addCase(fetch.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(fetchValidToken.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading start
    .addMatcher(isAnyOf(fetch.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(isAnyOf(fetch.rejected, fetch.fulfilled), (state) => ({
      ...state,
      loading: state.loading - 1,
    }));
});
