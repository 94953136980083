// Store
import { WorkflowDefinitionStatus } from "@store/modules/workflow-definition";
// Translations
import { lang } from "@lang/index";

/**
 * Defines a constant object `STATUS_TYPE` that maps each `WorkflowDefinitionStatus` value to a localized string using the `lang` function.
 *
 * @remarks
 * This object is typically used to display human-readable status labels for workflow definitions in the UI.
 *
 * @example
 * ```ts
 * const status = STATUS_TYPE[WorkflowDefinitionStatus.Active];
 * ```
 *
 * @see WorkflowDefinitionStatus
 */
export const STATUS_TYPE = {
  [WorkflowDefinitionStatus.Inactive]: lang("WORKFLOW_DEFINITION_INACTIVE"),
  [WorkflowDefinitionStatus.Active]: lang("WORKFLOW_DEFINITION_ACTIVE"),
  [WorkflowDefinitionStatus.Draft]: lang("WORKFLOW_DEFINITION_DRAFT"),
};

/**
 * Defines a constant object `EVENTS_TYPE` that maps each value to a localized string using the `lang` function.
 *
 * @remarks
 * This object is typically used to display human-readable events labels for workflow definitions in the UI.
 *
 * @example
 * ```ts
 * const event = EVENTS_TYPE["client.created"];
 * ```
 */
export const EVENTS_TYPE: Record<string, string> = {
  "client.created": lang("MESSAGE_EVENTS_CLIENT_CREATED"),
  "client.registered": lang("MESSAGE_EVENTS_CLIENT_REGISTERED"),
  "goal.created": lang("MESSAGE_EVENTS_GOAL_CREATED"),
  "account.created": lang("MESSAGE_EVENTS_ACCOUNT_CREATED"),
  "protection.created": lang("MESSAGE_EVENTS_PROTECTION_CREATED"),
};

/**
 * Defines a constant object `TASKS_TYPE` that maps each value to a localized string using the `lang` function.
 *
 * @remarks
 * This object is typically used to display human-readable tasks labels for workflow definitions in the UI.
 *
 * @example
 * ```ts
 * const task = TASKS_TYPE["create.agreement"];
 * ```
 */
export const TASKS_TYPE: { [key: string]: string } = {
  "create.agreement": lang("SERVICE_TASKS_CREATE_AGREEMENT"),
  "create.need.goal": lang("SERVICE_TASKS_CREATE_NEED_GOAL"),
  "create.objective.goal": lang("SERVICE_TASKS_CREATE_OBJECTIVE_GOAL"),
};
