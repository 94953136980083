/**
 * Converts a string to a readable format by replacing underscores with spaces
 * and inserting spaces before capital letters, then capitalizing the first letter.
 *
 * @param value - The string to convert.
 * @returns The converted readable string.
 */
export const readableString = (value: string): string =>
  value
    .replace("_", " ")
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
