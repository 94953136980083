// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Types
import { StateProps } from "./types";
// Initial state
import { initialState } from "./initial-state";

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state.
 *
 * @example
 * ```ts
 * Store.health.reset();
 * ```
 */

export const reset = createAction("health/reset");

/**
 * Gets the details of the API health check.
 *
 * @example
 * ```ts
 * void dispatch(
 *   Store.health.fetch()
 * );
 * ```
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  {
    authToken: string;
  }
>("health/fetch", async ({ authToken }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const response = await API.HealthApi(authToken).check();
    data = response.data;
  } catch (e) {
    error = API.parseError(e);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
