// Styles
import "./styles/index.scss";
// React
import React from "react";
// UI Components
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
// Classnames
import { useClassNames } from "@advicefront/ds-base";

export interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Skin color */
  skin?: "onahau" | "oasis" | "lavender";
  /** Icon to display to the left of the title content. */
  icon?: AfIconProps["name"];
}

/**
 * This component display Action Button.
 *
 * @example
 * ```tsx
 * <ActionButton skin="oasis" icon="arrow-circle-down" onClick={()=> console.log("hey")}>
 *   Children
 * </ActionButton>
 * ```
 *
 * @param props - RoutineCardProps
 */
export const ActionButton = ({
  icon = "basic-add-circle",
  skin = "onahau",
  onClick,
  children,
  className,
  ...nativeProps
}: ActionButtonProps): React.ReactElement => {
  const rootClassName = "action-button";
  const cssClasses = {
    root: useClassNames(rootClassName, `--${skin}`, className),
  };

  return (
    <button onClick={onClick} className={cssClasses.root} {...nativeProps}>
      <AfIcon name={icon} size="s" />
      {children}
    </button>
  );
};
