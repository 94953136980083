// React
import React, { ReactElement } from "react";
import { renderToString } from "react-dom/server";
// Translation Groups
import { dialog } from "./translations/dialog";
import { errors } from "./translations/errors";
import { forms } from "./translations/forms";
import { messageEvents } from "./translations/message-events";
import { notifications } from "./translations/notifications";
import { serviceTasks } from "./translations/service-tasks";
import { workflowDefinition } from "./translations/workflow-definition";
import { workflowDefinitions } from "./translations/workflow-definitions";

const allTranslations = {
  ...dialog,
  ...errors,
  ...forms,
  ...messageEvents,
  ...notifications,
  ...serviceTasks,
  ...workflowDefinition,
  ...workflowDefinitions,
};

export type Lang = "en";

export type Translations<T extends string> = Record<T, Record<Lang, string>>;

export const defaultLang: Lang = "en";

type TranslationEntry = keyof typeof allTranslations;

// TODO: Move to infra

/**
 * Returns translated matching the provided entry
 * @param key - translation entry name
 * @param values - parameters to include in translation
 * @param lang - language of the translation
 * @returns string / ReactElement
 */

function lang(
  key: TranslationEntry,
  values?: (string | number) | (string | number)[] | ReactElement | ReactElement[],
  lang?: Lang
): string {
  // Type casting on parameters to any values

  const l = lang || defaultLang;
  const langObj = allTranslations[key];
  const langValue = langObj && langObj[l];
  const computedValues = (values instanceof Array ? values : [values]) || [];

  const isReactNode = !["undefined", "string", "number"].includes(typeof computedValues[0]);

  const initialValue: string = langValue || `{${key}}`;

  let result: (undefined | string | number | ReactElement)[] = [initialValue];

  computedValues.forEach((value, index) => {
    result = result
      .map((v) => {
        if (typeof v !== "string") return v;

        const parsed = v.split(`{${index}}`).map((part, i) => (i === 0 ? [part] : [value, part]));

        return parsed;
      })
      .flat(2);
  });

  return isReactNode ? renderToString(<>{result}</>) : result.join("");
}

export { lang };
