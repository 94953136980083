// React
import React, { useState, useCallback, useMemo, useEffect } from "react";
// UI Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfAvatar } from "@advicefront/ds-avatar";
import { AfSelect } from "@advicefront/ds-select";
import { RoutineCard } from "@components/card";
// Store
import { MessageEventDefinitionDto } from "@store/modules/message-events";
// Translations
import { lang } from "@lang/index";
// Constants
import { EVENTS_TYPE } from "@constants/index";
// Assets
import EventIconURL from "@assets/icon/event.svg?url";

interface EventFormProps {
  /** Check is action status is Active/Published */
  isWorkflowDefinitionActive: boolean;
  /** Set the selected message event. */
  selectedEventType?: string;
  /** List of message events tasks.  */
  messageEvents?: MessageEventDefinitionDto[];
  /** Callback fired when click in button save. */
  onSave: (value: string) => void;
  /** Callback fired when the user commits a value change, also return if exists data to save. */
  onChange?: (options: { isFormDataSaved: boolean }) => void;
}

/**
 * This EventForm component displays `<form />` to create/edit message events.
 * Message events are events which reference a message; they are used to wait until a proper message is received.
 *
 * @see {@link https://docs.camunda.io/docs/components/modeler/bpmn/message-events/}
 *
 * @example
 * ```tsx
 * <EventForm onSave={onHandleSave} />
 * ```
 *
 * @param props - EventFormProps
 */
export const EventForm = ({
  isWorkflowDefinitionActive,
  selectedEventType,
  messageEvents,
  onSave,
  onChange,
}: EventFormProps): React.ReactElement => {
  const [formData, setFormData] = useState<{ eventType?: string }>({
    eventType: selectedEventType,
  });

  // In case of form field is empty or new values are the sames as previous, will set hidden state in button save.
  const isFormDataSaved = useMemo(
    () => formData.eventType === selectedEventType,
    [selectedEventType, formData]
  );

  const options = useMemo(
    () =>
      messageEvents?.map(({ eventType }) => ({
        label: EVENTS_TYPE[eventType],
        value: eventType,
      })),
    [messageEvents]
  );

  const onHandleClean = useCallback(() => {
    setFormData({});
  }, []);

  const onHandleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      const { eventType } = formData;
      if (eventType) onSave(eventType);
    },
    [formData, onSave]
  );

  useEffect(() => {
    if (onChange) onChange({ isFormDataSaved });
  }, [onChange, isFormDataSaved]);

  return (
    <form onSubmit={onHandleSubmit}>
      <RoutineCard
        title={lang("MESSAGE_EVENTS_TITLE")}
        icon={<AfAvatar size="s" nativeImgProps={{ src: EventIconURL }} />}
        isSubmitHidden={isFormDataSaved}
        isDropdownDisabled={isWorkflowDefinitionActive}
        onClean={onHandleClean}
      >
        <AfRow orientation="vertical">
          <AfCol>
            <AfSelect
              disabled={isWorkflowDefinitionActive}
              label={lang("FORMS_MESSAGE_EVENTS")}
              placeholder={lang("FORMS_PLACEHOLDER_MESSAGE_EVENTS")}
              value={formData.eventType}
              onChange={(eventType): void => {
                setFormData({ eventType });
              }}
              options={options}
              required
            />
          </AfCol>
        </AfRow>
      </RoutineCard>
    </form>
  );
};
