// Generic actions and terms
export const forms = {
  FORMS_NAME: {
    en: "Name",
  },
  FORMS_DESCRIPTION: {
    en: "Description",
  },
  FORMS_START_EVENT_SEQUENCE_ID: {
    en: "Start Event Sequence ID",
  },
  FORMS_FIRM_ID: {
    en: "Firm ID",
  },
  FORMS_CREATE: {
    en: "Create",
  },
  FORMS_SAVE: {
    en: "Save Changes",
  },
  FORMS_PLACEHOLDER_TEXT: {
    en: "Enter a {0}",
  },
  FORMS_PLACEHOLDER_SELECT: {
    en: "Select an {0}",
  },
  FORMS_BACK: {
    en: "Back",
  },
  FORMS_CANCEL: {
    en: "Cancel",
  },
  FORMS_MESSAGE_EVENTS: {
    en: "Message Events (Trigger)",
  },
  FORMS_PLACEHOLDER_MESSAGE_EVENTS: {
    en: "Select a trigger",
  },
  FORMS_SERVICE_TASKS: {
    en: "Service tasks (Actions)",
  },
  FORMS_PLACEHOLDER_SERVICE_TASKS: {
    en: "Choose an action",
  },
  FORMS_LABEL_CONDITION_AND: {
    en: "Continue if",
  },
  FORMS_PLACEHOLDER_CONDITION_SELECT: {
    en: "Choose",
  },
  FORMS_PLACEHOLDER_CONDITION_OPERATOR: {
    en: "Operator",
  },
  FORMS_PLACEHOLDER_CONDITION_VALUE: {
    en: "Value",
  },
  FORMS_BUTTON_CONDITION_AND: {
    en: "And",
  },
  FORMS_PUBLISH: {
    en: "Publish",
  },
  FORMS_PUBLISHED: {
    en: "Published",
  },
  FORMS_CLEAN: {
    en: "Clean {0}",
  },
  FORMS_DELETE: {
    en: "Delete",
  },
};
