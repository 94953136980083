// React
import React, { useRef, useMemo, useEffect, useState } from "react";
// Redux
import { Store, StoreState } from "@store/index";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { useSnackbar } from "@advicefront/ds-snackbar";

interface GlobalNotificationsProps {
  inlineNotifications: StoreState["notifications"]["data"] | null;
}

/**
 * Manage notification rendering and remove using DS snackbar
 * with data from notification store
 */
export const useGlobalNotifications = (): GlobalNotificationsProps => {
  // Store
  const notifications = Store.useSelector((state) => state.notifications);
  const dispatch = Store.useDispatch();

  // State
  const [isMounted, setIsMounted] = useState(false);

  // Snackbar
  const { addSnackbar, removeSnackbar } = useSnackbar();

  // Breaking Notifications that will not be displayed in snackbar but in an AfAlert
  const inlineNotifications = useRef<null | StoreState["notifications"]["data"]>(null);

  useEffect(() => {
    // Keep notification even if it's removed from state
    if (notifications.data.length === 0) return;
    inlineNotifications.current = notifications.data.filter(
      (notification) => notification.details?.type === "inline"
    );
  }, [notifications]);

  const floatingNotifications = useMemo(
    () =>
      notifications.data.filter(
        (notification) => !notification.details || notification.details.type === "floating"
      ),
    [notifications.data]
  );

  useEffect(() => {
    if (!isMounted || !floatingNotifications) return;

    // Loop through and display notifications
    floatingNotifications.forEach((notification) => {
      const id = addSnackbar((id) => (
        <AfAlert
          title={notification.title}
          description={notification.description}
          skin={notification.type}
          onClose={(): void => {
            removeSnackbar(id);
          }}
        />
      ));

      void dispatch(Store.notifications.removeNotification(notification.id));
      // Remove notification after 8 seconds
      const timeout = setTimeout(() => removeSnackbar(id), 8000);

      return () => clearTimeout(timeout);
    });
  }, [isMounted, floatingNotifications, addSnackbar, removeSnackbar, dispatch]);

  /** Remove notification on custom hook unmount.
   * This avoid duplicate snackbars when changing views that use this same hook.
   */
  useEffect(
    () => () => {
      if (!notifications.data) return;
      notifications.data.forEach((notification) => {
        void dispatch(Store.notifications.removeNotification(notification.id));
      });
    },
    [dispatch, notifications.data]
  );

  useEffect(() => {
    // show notifications if the component is mounted
    setIsMounted(true);
  }, []);

  return { inlineNotifications: inlineNotifications.current };
};
