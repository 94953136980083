// React
import React from "react";
// React Router
import { NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import { getRoute } from "@routes/utils/get-route";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { ErrorLayout } from "@layouts/Error";

const Element = (): React.ReactElement => (
  <ErrorLayout
    title={lang("ERROR_PAGE_NOT_FOUND_TITLE")}
    description={lang("ERROR_PAGE_NOT_FOUND_DESCRIPTION")}
  >
    <NavLink to={generatePath(getRoute("workflowDefinitions"))}>
      {lang("ERROR_PAGE_BACK_TO_INITIAL_PAGE")}
    </NavLink>
  </ErrorLayout>
);

export const error404: View = {
  name: "404 Error",
  options: {
    path: "*",
    element: <Element />,
  },
};
