import {
  CreateWorkflowDefinition,
  MergeWorkflowDefinition,
} from "@store/modules/workflow-definition";

/** Type guard to check if current data is CreateWorkflowDefinition
 * @see MergeWorkflowDefinition
 **/
export const isCreateWorkflowDto = (
  data: MergeWorkflowDefinition
): data is CreateWorkflowDefinition => "name" in data && data.name !== undefined;
