// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { reset, fetch, update, archive, create, status } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get data fulfilled
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        create.fulfilled,
        update.fulfilled,
        status.fulfilled,
        archive.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading start
    .addMatcher(
      isAnyOf(create.pending, fetch.pending, update.pending, status.pending, archive.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        create.rejected,
        create.fulfilled,
        update.rejected,
        update.fulfilled,
        fetch.rejected,
        fetch.fulfilled,
        status.rejected,
        status.fulfilled,
        archive.rejected,
        archive.fulfilled
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
