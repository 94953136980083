// Generic actions and terms
export const errors = {
  // 404 page
  ERROR_PAGE_NOT_FOUND_TITLE: {
    en: "Something’s missing",
  },
  ERROR_PAGE_NOT_FOUND_DESCRIPTION: {
    en: "The requested page was not found.",
  },
  ERROR_PAGE_BACK_TO_INITIAL_PAGE: {
    en: "Back to initial page",
  },
  // Session error page
  ERROR_PAGE_AUTH_TITLE: {
    en: "Authentication Error",
  },
  ERROR_PAGE_AUTH_DESCRIPTION: {
    en: "Make sure you're properly authenticated.",
  },
  // Generic error page
  ERROR_PAGE_GENERIC_TITLE: {
    en: "Something’s missing",
  },
  ERROR_PAGE_GENERIC_DESCRIPTION: {
    en: "We could not complete the requested action. Please report this error to our team.",
  },
};
