import { StoreState, Store } from "@store/index";
import { ThunkDispatch, AnyAction, isFulfilled } from "@reduxjs/toolkit";
import jwtDecode, { JwtPayload } from "jwt-decode";

/**
 * Gets token expiration date timestamp
 * @param authToken - token
 * @returns - token expiration date
 */
const getExpirationTimestamp = (authToken: string | undefined): number | undefined => {
  if (!authToken) return 0;

  const validity = jwtDecode<JwtPayload>(authToken).exp;
  return validity ? validity * 1000 : undefined;
};

/**
 * Return if authtoken is expired
 * @param authToken - token
 * @returns A `boolean` indicating whether the token is expired or not.
 */
export const isTokenExpired = (authToken: string | undefined): boolean => {
  if (!authToken) return true;

  const expirationDate = getExpirationTimestamp(authToken);
  if (expirationDate && +new Date() > expirationDate) return true;

  return false;
};

/**
 * Get's a valid authentication token or throws
 * @param dispatch - redux dispatch function
 * @returns - not expired auth token
 */
export const getAuthToken = async (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
): Promise<string> => {
  let authToken: string | undefined;

  // dispatch action to fetch a valid token
  const action = await dispatch(Store.auth.fetchValidToken());

  if (isFulfilled(action) && action.payload.error) {
    throw new Error("Unable to refresh user session");
  }

  if (isFulfilled(action)) {
    authToken = action.payload.authToken;
  }

  if (!authToken) {
    throw new Error("Unable to get valid session");
  }

  return authToken;
};

/**
 * Waits for the first dispatch to be done and returns the new authToken or throws
 * @param getState - redux `getState` function
 * @returns - auth token
 */
export const waitForAuthToken = async (
  getState: () => StoreState,
  recheckTimer = 50
): Promise<string | undefined> =>
  await new Promise<string | undefined>((resolve, reject) => {
    const interval = setInterval(() => {
      const isFetching = !!getState().auth.loading;
      const error = getState().auth.error;

      if (error) {
        clearInterval(interval);
        reject(new Error(error));
      }

      if (!isFetching) {
        clearInterval(interval);
        resolve(getState().auth.authToken);
      }
    }, recheckTimer);
  }).catch((error: Error) => {
    throw error;
  });
